import axios from "axios";
import config from "../../config";
import getToken from "../api/getToken";
const { BaseUrl } = config;

const deleteEnExams = async (studentid, examEnLectureId) => {
  try {
    const token = await getToken();
    const response = await axios.delete(`${BaseUrl}/student/onboard/exams/deleteenexam`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        studentid,
        examEnLectureId,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Report error: ", error);
    return [];
  }
};
//TODO: buradaki exam delete islemlerini tamamla...

const deleteItExams = async (studentid, examItLectureId) => {
  try {
    const token = await getToken();
    const response = await axios.delete(`${BaseUrl}/student/onboard/exams/deleteitexam`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        studentid,
        examItLectureId,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Report error: ", error);
    return [];
  }
};

export { deleteEnExams, deleteItExams };
