import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { filter } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, TableHead, Collapse, Grid, CardContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import BarChartIcon from '@mui/icons-material/BarChart';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Extract } from '../../assets/icons/extract.svg';
import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';
import  convertDateTime from '../../utils/convertDateTime';

import Scrollbar from '../../components/scrollbar'
// sections
import { MailchimpListHead, MailchimpListToolbar } from '../../sections/@dashboard/mailchimp';

//database
import { getMailchimpDatas } from '../../_data/mailchimp/getMailchimpDatas';
import { getIpAddressInfo } from '../../_data/mailchimp/getIpAddressInfo';
import SessionExpiredDialog from '../../components/session/sessionExpried';
import getSession from '../../utils/getSession'; //Session Management
import clearSession from '../../utils/clearSession';

import MasterDetails from './MailchimpPage'

import config from '../../config';
import { RestorePageOutlined } from '@mui/icons-material';
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filterStatusId, filterAllTags, filterOpenRate, filterClickRate) {
  if (!Array.isArray(array)) {
    return [];
  }
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]).filter((student) => {
    const lowerCaseQuery = String(query).toLowerCase(); 
    const matchQuery = query ?
      Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
      true;
     // Status ile eşleşen öğeleri kontrol edin
    const matchStatus = filterStatusId ? student.status === filterStatusId : true;
      
     // Tags degeri string oldugu icin yukaridaki gibi every ile kullanamadik every array dizileri icin gecerli.
    const matchTags = typeof filterAllTags === 'string' ? 
    student.tags && student.tags.includes(filterAllTags) : 
    true;
    // Eğer filterOpenRate varsa, avgOpenRate'yi filtrele (filtre değeri yüzdelik olduğundan, 100'e bölüyoruz)
    const matchOpenRate = filterOpenRate 
    ? (student.avgOpenRate * 100) <= filterOpenRate // Filtreleme eşit veya altında olacak şekilde
    : true;

    // Eğer filterClickRate varsa, avgClickRate'yi filtrele (filtre değeri yüzdelik olduğundan, 100'e bölüyoruz)
    const matchClickRate = filterClickRate 
    ? (student.avgClickRate * 100) <= filterClickRate // Filtreleme eşit veya altında olacak şekilde
    : true;
      // Tüm filtrelerin true olduğu durumlarda elemanı döndür
      return matchQuery && matchStatus && matchTags && matchOpenRate && matchClickRate;
  });
};


export default function MailchimpPage() {
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  // -------------------------COLUMN TITLES-------------------------------------------------- -----

  const TABLE_HEAD = [
    // { id: 'memberid', label: 'Member Id', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },    
    { id: 'fullname', label: 'Full Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'optInTimestamp', label: 'Subscription Time', alignRight: false }, // Abonelik Onayı Zamanı - Subscription Confirmation Time
    { id: 'avgOpenRate', label: 'Average Open Rate', alignRight: false }, // Ortalama Açılma Oranı - Average Open Rate
    { id: 'avgClickRate', label: 'Average Click Rate', alignRight: false },  // Ortalama Tıklama Oranı - Average Click Rate
    { id: 'lastChanged', label: 'Last Change Time', alignRight: false },  // Son Değişiklik Zamanı - Last Change Time
    { id: 'tags', label: 'Tags', alignRight: false },  //  Etiketler - Tags
    // { id: 'language', label: 'language', alignRight: false },  // Tercih Edilen Dil - Preferred Language
    // { id: 'vip', label: 'vip', alignRight: false },  // VIP Statüsü - VIP Status
    // { id: 'emailType', label: 'Email Format', alignRight: false },  // E-posta Formatı - Email Format    
    // { id: 'ipSignup', label: 'Sign-up IP Address', alignRight: false },  // E-posta Formatı - Confirm IP Address
    { id: 'ipOptIn', label: 'Confirm IP Address', alignRight: false },  // Opt-in IP Adresi - Opt-in IP Address
    // { id: '' },
    //memberid, email, firstName, lastName, status, optInTimestamp, avgOpenRate, avgClickRate, lastChanged, language, ipSignup, vip, emailType, tags, ipOptIn
  ];
  // ---------------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [filterBatchName, setfilterBatchName] = useState('');
  const [filterAllTags, setfilterAllTags] = useState('');
  const [filterOpenRate, setfilterOpenRate] = useState(false);
  const [filterClickRate, setfilterClickRate] = useState(false);
  const [filterFirstRecord, setfilterFirstRecord] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [mailchimpDatas, setMailchimpDatas] = useState([]);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const [nextTransactions, setNextTransactions] = useState([]);
  const [firstStudentRecords, setFirstStudentRecords] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [newTotalTasks, setNewTotalTasks] = useState(0);
  const [isCoordinatorLeader, setIsCoordinatorLeader] = useState(false);
  const [nextPage, setNextPage] = useState(null); // Bir sonraki sayfa URL'si
  const [prevPage, setPrevPage] = useState(null); // Önceki sayfa URL'si
  const navigate = useNavigate();
  const [openRow, setOpenRow] = useState(null); // For tracking collapsible row
  
  // const handleOpenCollapse = (memberid) => {
  //   setOpenRow(openRow === memberid ? null : memberid); // Toggle row open/close
  // };
  

  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Delete ', },
  ];

  const [openParticipantsDialog, setOpenParticipantsDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState();

  const handleEnrollmentDialogOpen =  async (items) => {
    try {      
      if (!items || Object.keys(items).length === 0) {
        return;
      }          
     const response = await getMailchimpDatas();     
      const data = response.data || [];
      const formattedData = data?.map(item => {
        const emailField = item.field_data.find(field => field.name === 'email');
        const fullNameField = item.field_data.find(field => field.name === 'full_name');
        const whatsappField = item.field_data.find(field => field.name === 'whatsapp_numaranız?');
        return {
          id: item.id,
          created_time: item.created_time,
          email: emailField ? emailField.values[0] : '',
          full_name: fullNameField ? fullNameField.values[0] : '',
          whatsapp: whatsappField ? whatsappField.values[0] : '',
        };
      });
      setCurrentAction(formattedData);
      setOpenParticipantsDialog(true);
    } catch (error) {  
      console.log('error: ', error);
    }  
  };

  const handleAdsParticipantsDialogClose = () => {
    setOpenParticipantsDialog(false);
  };


  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
      if(selected.length > 0){
        switch(label){   
          case t('LFP'):
            console.log("selected[0]: ", selected[0]);
            handleEnrollmentDialogOpen(selected[0]); 
            break;       
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  }; 
  
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const handleSessionExpiration = () => {
    setIsSessionExpired(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const session = getSession();
  const dynamicStructure = session && session.AllRoles ? session.AllRoles : [];

  const getMailchimpData = async () => {
    try {
      showLoading();      
      const data = await getMailchimpDatas();
      setRecordsNumber(data.length);
      setMailchimpDatas(data);
    } catch (error) {
        console.error(error);
        setMailchimpDatas([]);
    }finally{
      hideLoading();
    }
  };

  // const fetchData = async (url) => {
  //   try {
  //     const response = await getMailchimpDatas(url); // API çağrısı
  //     const data = response.data.data;
  //     const paging = response.data.paging || {};

  //     setMailchimpDatas(data); // Verileri kaydet
  //     setNextPage(paging.next || null); // Next URL'yi kaydet
  //     setPrevPage(paging.previous || null); // Previous URL'yi kaydet
  //     setRecordsNumber(data.length);
  //   } catch (error) {
  //     console.error('Error get data:', error);
  //   }
  // };


  useEffect(() => {
    getMailchimpData();
  }, []);

  const [filterRecords, setFilterRecords] = useState();
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const handleOpenMenu = (event, { id, name, status, created_time, leads_count }) => {
    setOpen(event.currentTarget);
    setSelected([{ id: id, name: name, status: status, created_time: created_time, leads_count: leads_count }]);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (!Array.isArray(filteredStudents)) {
        return;
      }
      const newSelecteds = filteredStudents?.map((n) => ({
        id: n.idstudents,
        mail: n.email,
        contact: n.contact,
        fullname: `${n.name} ${n.surname}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }  
    setSelected(newSelected);
  };

  const refreshLeadFormDatas = () => {
    getMailchimpData();
  };
  
  const handleConfirm = () => {    
    handleConfirmDialogClose();
  };

  const handleConfirmExtract = () => {    
    handleConfirmExractDialogClose();
  };
  
  const handleConfirmExtractDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmExractDialogClose = () => {
    setOpenExtractConfirmDialog(false);
  };
  
  
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // When Page Changed
  // const handleChangePage = (event, newPage) => {
  //   if (newPage > page && nextPage) {
  //     console.log("Next-Url: ", nextPage);
  //     fetchData(nextPage); // İleri sayfa için API'yi çağır
  //   } else if (newPage < page && prevPage) {
  //     fetchData(prevPage); // Geri sayfa için API'yi çağır
  //   }
  //   setPage(newPage);
  // };

  // Sayfalama işlemleri
  const handleChangePage = (event, newPage) => {
    showLoading();
    setPage(newPage); // Yeni sayfaya geçiş
    hideLoading();
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleConfirmDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenConfirmDialog(true);
  };

  const [openConfirmExtractDialog, setOpenExtractConfirmDialog] = useState(false);
  const handleConfirmExtractDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenExtractConfirmDialog(true);
  };

  const handleChangeRowsPerPage = (event) => {
    // showLoading();
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    // hideLoading();
    if (event.target.value === -1) {
      showLoading(); // Yüklenme başladığını göster
      setTimeout(() => { // Veriyi işlemeyi simüle etmek için zaman aşımı kullanıyoruz
        setRowsPerPage(parseInt(event.target.value, 10)); // Tüm veriyi göstermek için sayfa başına satır sayısını -1 yapıyoruz
        setPage(0); // Sayfayı sıfırlıyoruz
        hideLoading(); // Yüklenme tamamlandığını göster
      }, 1000); // 1 saniyelik bir gecikme ekliyoruz; bu süreyi ihtiyaca göre ayarlayabilirsiniz
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };


  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const [activeCard, setActiveCard] = useState(null);
  const handleFilterStatusByName = (newValue) => {
    try {
      showLoading();
      if (!newValue) {
        setPage(0);
        setfilterStatusId('');
        setActiveCard(null); // Hiçbir kart seçili değilse activeCard'ı null yapıyoruz
        return;
      }
      setPage(0);
      setfilterStatusId(newValue.id);
      setActiveCard(newValue.id); // Tıklanan kartı activeCard olarak ayarlıyoruz
    } catch (error) {
      console.log("error: ", error);
    }finally{
      hideLoading();
    }
  };


  const handleFilterBatchByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterBatchName('');
      return;
    }
    setPage(0);
    setfilterBatchName(newValue.id);
  };

  const handleFilterAllTagsByName = (newValue) => {
    try {
      showLoading();
      if (!newValue) {
        setPage(0);
        setfilterAllTags('');
        hideLoading();
        return;
      }
      setPage(0);
      setfilterAllTags(newValue.label);
      hideLoading();
    } catch (error) {
      console.log("Error: ", error);
    }finally{
      hideLoading();
    }
  };

  const handleFilterByOpenRate = (newValue) => {
    try {
      showLoading();
      if (newValue) {      
        console.log("handleFilterByOpenRate:", newValue.value);
        setPage(0);
        setfilterOpenRate(newValue.value);
      } else{      
        setPage(0);
        setfilterOpenRate(0);
        return;
      }      
    } catch (error) {
      console.log("[Error]-handleFilterByOpenRate: ", error);
    }finally{
      hideLoading();
    }
  };

  const handleFilterByClickRate = (newValue) => {
    try {
      showLoading();
      if (newValue) {      
        console.log("handleFilterByClickRate:", newValue.value);
        setPage(0);
        setfilterClickRate(newValue.value);
      } else{      
        setPage(0);
        setfilterClickRate(0);
        return;
      }      
    } catch (error) {
      console.log("[Error]-handleFilterByClickRate: ", error);
    }finally{
      hideLoading();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mailchimpDatas.length) : 0;
  //console.log("filterAllTags: ", filterAllTags);
  const filteredStudents = applySortFilter(mailchimpDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterAllTags, filterOpenRate, filterClickRate);
  //console.log("filteredStudents: ", filteredStudents.length);
  const isNotFound = Array.isArray(filteredStudents) && !filteredStudents.length && 
  (!!filterStudentId || !!filterStatusId || !!filterAllTags || !!filterOpenRate || !!filterClickRate);
  const validFilteredStudents = Array.isArray(filteredStudents) ? filteredStudents : [];

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 16);
    return formattedDate;
  };

  
  const isPastOrToday = (date) => {
    const berlinTimeString = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const berlinTime = new Date(berlinTimeString);
    const givenDate = new Date(date);
    const startOfToday = new Date(berlinTime.getFullYear(), berlinTime.getMonth(), berlinTime.getDate(), 0, 0, 0);
    if (givenDate <= startOfToday) {
      return true; 
    }
    return false; 
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 olduğu için +1 yapılıyor
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [locationData, setLocationData] = useState(null);
  // Handle opening the popover
  const handleClickIpAddresInfo = async (event, ipOptIn) => {
    setAnchorEl(event.currentTarget);
    try {
      showLoading();
      // Backend API call to get location data
      //console.log("ipOptIn: ", ipOptIn);
      const IpAddress = ipOptIn;
      const ipInfo = await getIpAddressInfo(IpAddress);
      //console.log("responde IP Address: ", ipInfo);
      setLocationData(ipInfo); // Assuming response contains country and city
    } catch (error) {
      console.error('Error fetching IP location:', error);
      setLocationData({ error: 'Could not fetch location data' });
    } finally {
      hideLoading();
    }
  };
  // Handle (Where is the IP address from?) closing the popover
  const handleIpAddresInfoClose = () => {
    setAnchorEl(null);
    setLocationData(null); // Reset location data
  };
  const openIpAddresInfo = Boolean(anchorEl);
  const id = openIpAddresInfo ? 'ip-location-popover' : undefined;
  const [groupedStatus, setGroupedStatus] = useState({});

  useEffect(() => {
    showLoading();
    if (mailchimpDatas && mailchimpDatas.length > 0) {
      const grouped = groupByStatus(mailchimpDatas); // Dataset'i grupluyoruz
      setGroupedStatus(grouped);
    }
    hideLoading();
  }, [mailchimpDatas]); 

  const groupByStatus = (data) => {
    return data.reduce((acc, curr) => {
      const status = curr.status || 'No Status'; // Null veya undefined olanları 'No Status' olarak grupluyoruz
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});
  };


  // Kartın genel stilini tanımlıyoruz.
  const StyledCard = styled(Card)(({ theme }) => ({
    transition: theme.transitions.create(['transform', 'box-shadow'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: theme.shadows[10],
    },
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }));

  // İkon stilini tanımlıyoruz.
  const StatusIcon = styled(Iconify)(({ theme }) => ({
    fontSize: '3rem',
    color: theme.palette.primary.main,
  }));

  // Kart başlığı stilini tanımlıyoruz.
  const CardTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
  }));

  // Kartta gösterilecek sayı stilini tanımlıyoruz.
  const CardNumber = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    fontWeight: theme.typography.fontWeightBold,
  }));

  // Kart açıklama stilini tanımlıyoruz.
  const CardDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.disabled,
    fontSize: theme.typography.body2.fontSize,
  }));

  const getStatusIcon = (status) => statusIcons[status] || 'eva:info-fill';
  const statusIcons = {
    subscribed: 'eva:checkmark-circle-2-fill',
    unsubscribed: 'eva:close-circle-fill',
    pending: 'eva:clock-fill',
    cleaned: 'eva:trash-2-outline',
    // Diğer status'ler için ikonlar ekleyin
  };

  return (
    <>    
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>     
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid container spacing={3}>
            {/* Mailchimp List and Total Records */}
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard 
                onClick={() => handleFilterStatusByName(null)} 
                 sx={{ cursor: 'pointer', height: '100%' }} // Ensure full height and pointer on hover
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <BarChartIcon style={{ fontSize: 30, color: 'purple' }} />
                    <Typography variant="h6" color="primary" gutterBottom>
                      Mailchimp List
                    </Typography>
                    <Typography variant="h4" color="textPrimary">
                      {recordsNumber}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Total Records
                    </Typography>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Status Cards with Clickable Filters */}
            {Object.entries(groupedStatus).map(([status, count]) => (
              <Grid item xs={12} sm={6} md={3} key={status}>
                <StyledCard 
                  onClick={() => handleFilterStatusByName({ id: status })} 
                  // sx={{ cursor: 'pointer', height: '100%' }} // Ensure full height and pointer on hover

                  sx={{
                    cursor: 'pointer',
                    height: '100%',
                    boxShadow: activeCard === status ? '0 4px 20px rgba(0, 0, 0, 0.2)' : '0 1px 5px rgba(0, 0, 0, 0.1)',
                    border: activeCard === status ? '2px solid #3f51b5' : '1px solid rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s ease, border 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 6px 25px rgba(0, 0, 0, 0.3)',
                    },
                  }}

                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      {/* Status Icon */}
                      <StatusIcon icon={getStatusIcon(status)} />
                      <Typography variant="h6" color="primary" gutterBottom>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </Typography>
                      <Typography variant="h4" color="textPrimary">
                        {count}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {`Total ${status.charAt(0).toUpperCase() + status.slice(1)} members`}
                      </Typography>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="body1" color="secondary">
                <span style={{ color: '#888', fontWeight: 'normal' }}>
                  {`Filtered Records: `}
                </span>
                <span style={{ color: '#E90074', fontWeight: 'bold' }}>
                  {filteredStudents.length}
                </span>
              </Typography>
            </Grid>
          </Grid>
          
        </Stack>
        <Card>
          <MailchimpListToolbar 
            numSelected={selected.length} 
            filterName={filterStudentId} 
            onFilterName={handleFilterByName}  
            onTags={handleFilterAllTagsByName}            
            onOpenRate={handleFilterByOpenRate}
            onClickRate={handleFilterByClickRate}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MailchimpListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                {                  
                  (rowsPerPage === -1 ? validFilteredStudents : validFilteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row, index) => {
                      const { memberid, email, firstName, lastName, status, optInTimestamp, avgOpenRate, avgClickRate, lastChanged, language, ipSignup, vip, emailType, tags, ipOptIn } = row;                      
                      const selectedStudent = selected.indexOf(memberid) !== -1;
                      const isItemSelected = selected.some(item => item.id === memberid);
                    return (
                    <React.Fragment key={index}>
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell align="left">
                          {email}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                        {firstName} {lastName}
                        </TableCell>                                              
                        <TableCell align="left">
                          <Label
                            sx={{ 
                              backgroundColor: status == "subscribed" ? 'defaultBackground' : '#ffcccc',
                              color:
                                status == "subscribed" ? 'darkgreen' : 
                                status == "unsubscribed" ? 'secondary.main' : 'error'
                            }}>
                              { status }
                          </Label>
                        </TableCell>                        
                        <TableCell align="left">
                          {formatDate(optInTimestamp)}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="The open rate of emails sent to this person.">
                            {/* {(avgOpenRate * 100).toFixed(2)}% */}
                            <span>{(avgOpenRate * 100).toFixed(2)}%</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="Click-through rate is a measure of the number of times a recipient clicks on links in email sent.">
                            <span>{(avgClickRate * 100).toFixed(2)}%</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(lastChanged)}
                        </TableCell>
                        <TableCell align="left">
                          {tags}
                        </TableCell>
                        {/* <TableCell align="left">
                        {vip === 0 ? 'NO' : 'YES'}
                      </TableCell> */}
                         <TableCell align="left">
                          <Tooltip title="Click to see IP address geographic information" arrow>
                            <span
                              onClick={(event) => handleClickIpAddresInfo(event, ipOptIn)}
                              style={{
                                backgroundColor: '#f1ffaa', // Arka plan rengi dikkat çekici hale getiriliyor
                                padding: '4px 8px',
                                borderRadius: '4px',
                                cursor: 'pointer', // Mouse imleci el işareti oluyor
                              }}
                            >
                              {ipOptIn}
                            </span>
                          </Tooltip>
                            <Popover
                              id={id}
                              open={openIpAddresInfo}
                              anchorEl={anchorEl}
                              onClose={handleIpAddresInfoClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                sx: {
                                  padding: 2, 
                                  borderRadius: 2,
                                  boxShadow: 3,
                                  maxWidth: '300px',
                                }
                              }}
                            >
                            <div style={{ padding: '16px', maxWidth: '300px' }}>
                              {locationData ? (
                                <Box>
                                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#0056b3' }}>
                                    IP Location Information:
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', mb: 1, mt:2 }}>
                                    Ip: <span style={{ fontWeight: 'normal', color: '#555' }}>{locationData?.ip || 'N/A'}</span>
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                                    Country: <span style={{ fontWeight: 'normal', color: '#555' }}>{locationData?.country_name || 'N/A'}</span>
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                                    Region: <span style={{ fontWeight: 'normal', color: '#555' }}>{locationData?.region_name || 'N/A'}</span>
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                                    City: <span style={{ fontWeight: 'normal', color: '#555' }}>{locationData?.city || 'N/A'}</span>
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                                    Time Zone: <span style={{ fontWeight: 'normal', color: '#555' }}>{locationData?.time_zone || 'N/A'}</span>
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
                                    Zip Code: <span style={{ fontWeight: 'normal', color: '#555' }}>{locationData?.zip_code || 'N/A'}</span>
                                  </Typography>
                                </Box>
                              ) : (
                                <p>Loading location data...</p>
                              )}
                            </div>
                          </Popover>
                        </TableCell>
                      </TableRow>
                  </React.Fragment>
                   
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={filteredStudents.length}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 205,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label0} onClick={handleDownRecord} sx={{ color: 'text.dark', Width: 300}}> 
          <Iconify icon={'eva:checkmark-square-2-outline'} sx={{ mr: 2 }} />
          {t('LFP')}
        </MenuItem>
      </Popover> */}

      {/* <Popover
        id={id}
        open={openIpAddresInfo}
        anchorEl={anchorEl}
        onClose={handleIpAddresInfoClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '16px', maxWidth: '300px' }}>
          {locationData ? (
            <>
              <strong>IP Location Information:</strong>
              <p>Country: {locationData.country}</p>
              <p>City: {locationData.city}</p>
            </>
          ) : (
            <p>Loading location data...</p>
          )}
        </div>
      </Popover> */}

      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} 
        sx={{ 
          width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
          fontStyle: 'normal',
          whiteSpace: 'pre-line'
        }} >
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected?.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The registration of the student(s) whose above information is visible will be deleted.
            <br/>
            Are you sure you want to do this?
            
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>        
      <Dialog
        open={openConfirmExtractDialog}
        onClose={handleConfirmExtractDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected?.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The interest and registration of the student(s) whose information is shown above will be removed from all platforms.
          </p>
              <br/>
              Are you sure you want to do this?          
        </DialogContent>
        <DialogActions>
          <Button sx = {{ pr: 10 }} onClick={handleConfirmExractDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            sx = {{ pr: 10, textAlign: 'center' }}
            onClick={handleConfirmExtract} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>

      <Dialog
        open={openParticipantsDialog}
        onClose={handleAdsParticipantsDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "80%", maxWidth: "none" } 
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {Array.isArray(currentAction) && currentAction.length > 0 && selected[0] && (
            <>
              <Typography variant="body4" component="span" style={{ color: 'red' }}>
                {selected[0].name}
              </Typography>
              <br/> 
              <Typography variant="body2" component="span" style={{ color: 'text' }}>
                Number of Participant : {currentAction.length}
              </Typography>
              
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Created Time</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>WhatsApp Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentAction?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{formatDate(row.created_time)}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.full_name}</TableCell>
                    <TableCell>{row.whatsapp}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>             
        </DialogContent>
        <DialogActions>
          <Button
            sx = {{ pl: 2, pr: 2, textAlign: 'center' }}
            onClick={handleAdsParticipantsDialogClose}
            autoFocus
            style={{
              backgroundColor: '#6C757D',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>          
        </DialogActions>        
      </Dialog>
    </>
  );
};