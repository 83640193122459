import React, { useState, useEffect, memo, useCallback } from 'react';
import { Stack, Typography, Card, CardContent, Grid, Paper, Divider, Box } from '@mui/material';
import getInstallmentDetails from '../../../src/_data/coursecoordination/getInstallmentDetails';
import { FormLabel, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import CustomPaymentOptions from './CustomPaymentOptions';

const PaymentOptionCards = ({ data, onSelect }) => {
  //const [selectedOptions, setSelectedOptions] = useState();
  const MemoizedCustomPaymentOptions = memo(CustomPaymentOptions);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedNestedCardId, setSelectedNestedCardId] = useState(null);
  const [selectedIds, setSelectedIds] = useState({selectedCardId, selectedNestedCardId});
  const [selectedInstallmentDetails , setSelectedInstallmentDetails ] = useState(null);
  const [radioGroup1 , setRadioGroup1 ] = useState('StandartOptions');

  useEffect(() => {
    setSelectedIds({ selectedCardId, selectedNestedCardId });
  }, [selectedCardId, selectedNestedCardId]);

  const handleCardSelect = useCallback((id, coursetypeid) => {
   // console.log('radioGroup1: ', radioGroup1);
   /// console.log('id: ', id);
    if (id !== undefined) {
        setSelectedCardId(id);
        getSelecedInstallmentDetails(id, coursetypeid);
      //  console.log("BBB-handleCardSelect-BBB: ", radioGroup1);
        if(radioGroup1 === 'StandartOptions'){
            onSelect({ selectedCardId: id, selectedNestedCard: selectedNestedCardId, paymentOptions: radioGroup1});
        }else{       
            onSelect({ selectedCardId: id, selectedNestedCard: selectedNestedCardId, paymentOptions: radioGroup1 }); //CustomOptions
        };
    }
  },[selectedNestedCardId, radioGroup1, onSelect]);
  
  const handleNestedCardSelect = useCallback((detail) => {
     if (detail.idpostemploymentpaymentoptions !== undefined) {
         setSelectedNestedCardId(detail.idpostemploymentpaymentoptions);
         //console.log('AAA-handleNestedCardSelect-AAA: ', detail, detail.idpostemploymentpaymentoptions) ;
         onSelect({ selectedCardId: selectedCardId, selectedNestedCard: detail, paymentOptions: radioGroup1});
     } else {
     }
  },[selectedCardId,  onSelect]);

  const getSelecedInstallmentDetails = async (id, coursetypeid)=>{
    try {
        if(coursetypeid === 1){
          const installmentDetail = await getInstallmentDetails(id);
          setSelectedInstallmentDetails(installmentDetail);
        }
    } catch (error) {
        console.log('Error: ', error);
    }  
  };

  const handleRadioChange1 = (event) => {
    setRadioGroup1(event.target.value);
    onSelect({ selectedCardId: null, selectedNestedCard: null, paymentOptions: event.target.value});
  };

  const handleCustomDetails = (details)=>{
    onSelect({
      ...details,
      paymentOptions: radioGroup1
    });
    //console.log("details: ", details);
  };

return (
    <>
    <FormControl component="fieldset">
    <Stack direction="row" alignItems="center" spacing={2}>        
        <FormLabel component="legend">Payment Options: </FormLabel>
        <RadioGroup
            row
            aria-label="option1"
            name="radioGroup1"
            value={radioGroup1}
            onChange={handleRadioChange1}
        >
            <FormControlLabel value="StandartOptions" control={<Radio />}label="Standart Options" />
            <FormControlLabel value="CustomOptions" control={<Radio />} label="Custom Options" />
        </RadioGroup>
        </Stack>
    </FormControl>
    { radioGroup1 === 'StandartOptions' ? (
    <Stack direction="row" alignItems="center" justifyContent="center">        
      <Paper
        sx={{
          border: '1px solid #ddd',
          borderRadius: '16px',
          padding: '16px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          mt: '5px',
          width: '100%',  // Panel genişliğini arttır
          maxWidth: '1800px', // Maksimum genişlik belirle
        }}
      >        
        <Grid container spacing={2} justifyContent="center">
          {data?.map((item) => (
            <Grid item key={item.courseregistrationfeesid} xs={12}>
              <Card
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  border: selectedCardId === item.coursefeeregiontypeid ? '2px solid blue' : 'none',
                  cursor: 'pointer',
                  width: '100%',
                }}
                onClick={() => handleCardSelect(item.coursefeeregiontypeid, item.coursetypeid)}
              >
                <CardContent>
                  <Typography sx={{ color: '#d32f2f', mt: '2px' }}>
                    Cash Amount: <span style={{ color: '#9c27b0', fontWeight: 'bold' }}>{item.currency}{item.cashamount}</span>
                  </Typography>
                  <Typography sx={{ color: '#1976d2', mt: '2px' }}>
                    Registration Fee: <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>{item.currency}{item.registrationfee}</span>
                  </Typography>
                  {item.paymentfrequency === 'INSTALLMENT' && (
                    <Typography sx={{ color: '#388e3c', mt: '2px' }}>
                      Payment Plan: <span style={{ color: 'red', fontWeight: 'bold' }}>{item.installmentcount} Months X {item.currency}{item.installmentamount}</span>
                    </Typography>
                  )}
                  <Typography sx={{ color: '#388e3c', mt: '2px' }}>
                    {/* Total Amount: <span style={{ color: '#2e7d32', fontWeight: 'bold' }}>{item.paymentfrequency === "CASH" ? `${item.currency} ${item.totalcashamount}` : `${item.currency} ${item.totalfirstamount}`}</span><br/>                     */}
                    Total Amount: <span style={{ color: '#2e7d32', fontWeight: 'bold' }}> {item.currency} {item.totalfirstamount}</span><br/>                    
                  </Typography>                  
                </CardContent>
              </Card>

              {/* {data[0]?.paymentfrequency === "INSTALLMENT" && (
                    <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                      {selectedCardId === item.coursefeeregiontypeid && selectedInstallmentDetails && (
                        selectedInstallmentDetails.map((detail) => (
                          <Grid item key={detail.idpostemploymentpaymentoptions} xs={12} sm={6} md={9} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card
                              sx={{
                                borderRadius: '12px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: selectedNestedCardId === detail.idpostemploymentpaymentoptions ? '2px solid red' : '2px solid blue',
                                cursor: 'pointer',
                                width: '100%',
                                maxWidth: '1200px',
                                display: 'flex',
                                flexDirection: 'column',
                                whiteSpace: 'nowrap',
                              }}
                              onClick={() => handleNestedCardSelect(detail)}
                            >
                              <CardContent>
                                {detail?.annualincomestartingending && (
                                  <Typography sx={{ color: '#ddddd', mt: '2px' }}>
                                    Incomes: <span style={{ color: '#1976d2', fontWeight: 'normal' }}>{detail.annualincomestartingending}</span>
                                  </Typography>
                                )}
                                <Divider sx={{ my: 1 }} />
                                {detail.installmentcount1 > 0 && (
                                  <Typography key={`plan1-${detail.idpostemploymentpaymentoptions}`}>
                                    Payment Plan: <span style={{ color: 'red', fontWeight: 'normal' }}>{detail.installmentcount1} Months X {item.currency}{detail.installmentamount1}</span>
                                  </Typography>
                                )}
                                {detail.installmentcount2 > 0 && (
                                  <>
                                    <Typography key={`plan2-${detail.idpostemploymentpaymentoptions}`} sx={{ color: 'red' }}>
                                      & <span style={{ color: 'red', fontWeight: 'normal' }}>{detail.installmentcount2} Months X {item.currency}{detail.installmentamount2}</span>
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                  </>
                                )}
                                <Typography sx={{ color: '#ddddd', mt: '2px' }}>
                                  Total: <span style={{ color: '#9c27b0', fontWeight: 'bold' }}>{item.currency}{detail.totalsecondamount}</span>
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))
                      )}
                    </Grid>)} */}

              {data[0]?.paymentfrequency === "INSTALLMENT" && (
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                    {selectedCardId === item.coursefeeregiontypeid && selectedInstallmentDetails && (
                    selectedInstallmentDetails.map((detail) => (
                        <Grid item key={detail.idpostemploymentpaymentoptions} xs={12} sm={6} md={9} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card
                                sx={{
                                borderRadius: '12px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid blue',
                                border: selectedNestedCardId === detail.idpostemploymentpaymentoptions ? '2px solid red' : 'none',
                                cursor: 'pointer',  
                                width: '100%', // Kart genişliğini artır
                                maxWidth: '1200px', // Maksimum genişlik belirle                          
                                display: 'flex',
                                flexDirection: 'column',                                
                                whiteSpace: 'nowrap',
                                }}
                                onClick={() => handleNestedCardSelect(detail)}
                            >
                                <CardContent>
                                    <Typography sx={{ color: '#ddddd', mt: '2px' }}>
                                        Incomes: <span style={{ color: '#1976d2', fontWeight: 'normal' }}>{detail?.annualincomestartingending}</span>                            
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    {/* <Typography sx={{ color: '#ddddd', mt: '2px'}}> */}
                                    <Box sx={{ color: '#ddddd', mt: '2px'}}>
                                        {detail.installmentcount1 > 0 && (
                                            <Typography key={`plan1-${detail.idpostemploymentpaymentoptions}`}>Payment Plan: <span style={{ color: 'red', fontWeight: 'normal' }}>{detail.installmentcount1} Months X {item.currency}{detail.installmentamount1}</span></Typography>
                                        )}
                                        {detail.installmentcount2 > 0 && (
                                          <>
                                            <Typography key={`plan2-${detail.idpostemploymentpaymentoptions}`}  sx={{color: 'red'}}> & <span style={{ color: 'red', fontWeight: 'normal' }}>{detail.installmentcount2} Months X {item.currency}{detail.installmentamount2}</span></Typography> 
                                            <Divider sx={{ my: 1 }} />
                                          </>
                                        )}
                                        </Box>
                                    {/* </Typography>                             */}
                                    <Typography sx={{ color: '#ddddd', mt: '2px' }}>
                                   
                                        Total: <span style={{ color: '#9c27b0', fontWeight: 'bold' }}>{item.currency}{detail.totalsecondamount}</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                    )}
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Stack>
    ):(
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Paper
            sx={{
              border: '1px solid #ddd',
              borderRadius: '16px',
              padding: '16px',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              mt: '5px',
              width: '100%',
              maxWidth: '1400px',
            }}
          >
            <CustomPaymentOptions options = {radioGroup1} regitrationfee = {{ currency: data[0]?.currency, fee: data[0]?.registrationfee }} paymentType = {data[0]?.paymentfrequency} customPaymentContract ={handleCustomDetails}/>
          </Paper>
        </Stack>
      )}
    </>
  );
};

export default memo(PaymentOptionCards);