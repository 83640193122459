import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Tabs, Tab, Box } from '@mui/material';
// components
import LeaveDashboard from '../../components/userProfile/LeaveDashboard';
import StaffInfo from '../../components/userProfile/StaffInfo';
import getSession from '../../utils/getSession'; //Session Management

// ----------------------------------------------------------------------

export default function UserProfile() {
  const Company = 'Oak Acadedmy'; // TODO: env dosyasindan veya databaseden almaliyiz.
  const session = getSession();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    //console.log("new value: ", newValue);
    setSelectedTab(newValue);
  };
  // TabPanel bileşeni - her sekmenin içeriğini gösterir
  const TabPanel = (props) => {
    const { children } = props;
    return (
      <Box sx={{ p: 1 }}>
        {children}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title> User Profile | {Company} </title>
      </Helmet>
      <StaffInfo staffInfo={session.user[0]}/>
      <Container maxWidth="800" sx={{ mt: 1 }}>        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Profile Tabs">
            <Tab label="Leave Dashboard" />
            {/* <Tab label="Edit Profile" /> */}
          </Tabs>
        </Box>
        {selectedTab === 0 && (
          <TabPanel>
            <LeaveDashboard userInfo={session.user[0]} />
          </TabPanel>
        )}
        {selectedTab === 1 && (
          <TabPanel>
            
          </TabPanel>
        )}        
      </Container>
    </>
  );
}

