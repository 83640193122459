import React, { useState, useRef, useEffect } from 'react';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid, FormLabel, 
Autocomplete, DialogContentText, TableContainer, Table, TableRow  } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { fDate, fDateX } from '../../utils/formatTime';

import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import creatstudent from '../../_data/student/createstudent';
import { useTranslation } from 'react-i18next'; //Multi Language
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from './PhoneInput';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import { getContactStates } from '../../_data/contacstates/getContactStates';
import { getContactChannels } from '../../_data/contactchannels/getContactChannels';
import { getAssessmentLevels } from '../../_data/assesmentlevels/getAssessmentLevels';
import createInterviewTransaction from '../../_data/student/updateNewInterviewTransaction';
import convertDateTime from '../../utils/convertDateTime';
import getAllCourseCoordinators from '../../_data/user/getAllCourseCoordination';

// utils
import formatDateTime from '../../utils/dateTimeFormatter';

export default function CreateTransactionDialog({ onTransactionAdded, follower, studentId, email }) {
  const { showLoading, hideLoading } = useLoading();
  //console.log('stdEmail: ', email);
  //const dateTimeString = new Date().toISOString();
  const account = getSession();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  //console.log('transaction follower: ', follower);
  const [followerId, setFollowerId] = useState(follower.followerId);
  const [followerName, setFollowerName] = useState(follower.followerName);
  // const [currentTime, setCurrentTime] = useState(convertDateTime(formatDateTime()));
  const [currentTime, setCurrentTime] = useState(formatDateTime());
  const [howmanycall, setHowManyCall] = useState(null);
  const [nextCall, setNextCall] = useState(dayjs().subtract(1, 'month'));
  const [allcoordinators, setAllCoordinators] = useState([]);
  const [coordinatorName, setCoordinatorName] = useState([]);
  const autocompleteCoordinatorRef = useRef(null);
  const [showNewTask, setShowNewTask] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSelectedTaskConfirmation, setOpenSelectedTaskConfirmation] = useState(false);

  let formattedDate = new Date();

  let year = formattedDate.getFullYear();
  let month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  let day = String(formattedDate.getDate()).padStart(2, "0");
  let today = `${year}-${month}-${day}`;

  const convertDateFormat = (dateStr) => {  
    if (!dateStr) {
      return 'Invalid Date';
    }
     // DD.MM.YYYY HH:MM:SS formatına uygun regex
    const parts = dateStr.match(/(\d{2})\.(\d{2})\.(\d{4}) (\d{2}:\d{2}:\d{2})/);
    if (!parts || parts.length < 5) {
      return 'Invalid Date';
    }
  }

  const [formData, setFormData] = useState({
    studentid: studentId,
    contactdatetime: convertDateFormat(currentTime),   
    contactchannel: '',
    contactstate: '' ,
    newaction: '' ,
    descriptionabout: '',
    assessmentlevel: '',
    howmanycall: 1,
    nextcalldatetime: '',
    transactionby: account.user[0].iduser
  }); 
  const handleMembershipCheckboxChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.checked
      }
    });
  };
  
  const [contactState, setContactState] = useState([])
  const [contactChannel, setContactChannel] = useState([])
  const [assessmentLevel, setAssessmentLevel] = useState([])

  useEffect(() => {
    clearForm();
    fetchContactState();
    fetchContactChannel();
    fetchAssessmentLevel();
    loadCoordinators();
    const timer = setInterval(() => {
      // setCurrentTime(convertDateTime(formatDateTime()));
      setCurrentTime(formatDateTime());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const clearForm = () => {
    //setFormData({ newaction: '', contactchannel: '', contactstate: '', descriptionabout: '', assessmentlevel: '', nextcalldatetime: '' });
    // setNextCall(null);
    // setContactChannel(null);
    // setContactState(null);
    // setAssessmentLevel(null);
    // setShowNewTask(false);
    setFormErrors({});
  };

  const fetchContactState = async () => {
    const data = await getContactStates();
    setContactState(data);
  };

  const fetchContactChannel = async () => {
    const data = await getContactChannels();
    setContactChannel(data);
  };

  const fetchAssessmentLevel = async () => {
    const data = await getAssessmentLevels();
    setAssessmentLevel(data);
  };

  dayjs.extend(utc);
  const handleNextCallChange = (newValue) => {
    //console.log('newValue.UTC: ', newValue.utc().format());
    if (dayjs.isDayjs(newValue)) {
      let utcDate = newValue.utc().format();
      //console.log('UTC Date:', utcDate);
      // newValue'yi Dayjs objesine çevirip, UTC'ye ayarla ve MySQL formatında formatla
      let mysqlDatetime = dayjs(utcDate).utc().format('YYYY-MM-DD HH:mm:ss');
      setNextCall(newValue);
      //console.log('mysqlDatetime:', mysqlDatetime);
      setFormData({ ...formData, nextcalldatetime: mysqlDatetime});
      //console.log('setFormData: ', formData);
    } else {
       // console.log('newValue is not a Dayjs object:', newValue);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
        showMessage('Create e-Mail User & Password copied.');
      } else {
        // Eski tarayıcılar için alternatif yöntem
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        showMessage('Create e-Mail User & Password copied.');
      }
    } catch (err) {
      console.error('Could not copy password to clipboard!', err);
    }
  };

  const loadCoordinators = async () => {
    try {
       const response = await getAllCourseCoordinators();
       setAllCoordinators(response);
    } catch (error) {
        console.error('Error loading loadCoordinators:', error);
    }
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  //const [alertMessage, setAlertMessage] = useState('');
  const [alertMessages, setAlertMessages] = useState([]); // Burada alertMessage yerine alertMessages kullanıyorum ve başlangıç değeri olarak boş dizi veriyorum
  const showMessage = (message, severityType = 'warning', duration = 3000) => {
   // console.log('message: ', message);
    const id = new Date().getTime();  // Unique ID olarak zaman damgasını kullanabiliriz.
    const newMessage = { id, message, severityType };
    //setAlertMessage(message);
    setAlertMessages(prevMessages => [...prevMessages, newMessage]);

    setSeverity(severityType);
    setMsgOpen(true);

    // Belirlenen süre sonunda mesajı kaldır
    setTimeout(() => {
      setAlertMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
    }, duration);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // setOpenSelectedTaskConfirmation(true); //TODO? Task kontrolunu sonra yap.
    // if (openSelectedTaskConfirmation) {
    //   setOpen(true);
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContactChannels = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, contactchannel: value });
  };
  
  const handleChangeHowManyCall = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, howmanycall: value});
  };

  const handleChangeContactState = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, contactstate: value });
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if(name === 'contact.mail.mailaddress1'){
        setFormData({ ...formData, email: value });
      }
      const nameParts = name.split('.'); // Nokta ile ayrılmış yolu parçalara ayır
      setFormData((prevFormData) => {
        let updatedValue = { ...prevFormData }; // İlk kopyayı oluştur
        nameParts.reduce((acc, key, index) => {
          if (index === nameParts.length - 1) {
            // Eğer son parçadaysak değeri güncelle
            acc[key] = value;
          } else {
            // Eğer son parça değilse, bir sonraki parçaya geç
            acc[key] = acc[key] || {}; // Eğer bir sonraki anahtar yoksa, yeni bir nesne oluştur
          }
          return acc[key];
        }, updatedValue);
        return updatedValue; // Güncellenmiş değeri döndür
      });
    // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleChangeDescription = (event) => {
    //const { name, value } = event.target;
    setFormData({ ...formData, descriptionabout: event.target.value });
   // console.log('setFormData: ', formData);
  };

  const handleChangeAssessmentLevel = (event) => {
   // console.log('formData.assessmentlevel: ', formData.assessmentlevel);
  //  console.log('event: ', event);
    setFormData({ ...formData, assessmentlevel: event.target.value });
   // console.log('setFormData: ', formData);
  };

  const handleIncrement = () => {
    setHowManyCall(prevCount => prevCount + 1);
  };

  const handleDecrement = () => {
    setHowManyCall(prevCount => prevCount - 1);
  };

  const contactstateRef = useRef(null);
  const contactchannelRef = useRef(null);
  const assessmentlevelRef = useRef(null);
  const newactionRef = useRef(null);
  const nextcalldatetimeRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);

  const validateForm = () => {
    let errors = {};
    let firstError = null;

    if (!formData.contactstate) {
      errors.contactstate = true;
      firstError = firstError || 'contactstate';
    }

    if (!formData.contactchannel) {
      errors.contactchannel = true;
      firstError = firstError || 'contactchannel';
    }

    if (!formData.assessmentlevel) {
      errors.assessmentlevel = true;
      firstError = firstError || 'assessmentlevel';
    }

    if (!formData.newaction) {
      errors.newaction = true;
      firstError = firstError || 'newaction';
    }

    if(showNewTask){
      if (!formData.nextcalldatetime) {
        errors.nextcalldatetime = true;
        firstError = firstError || 'nextcalldatetime';
      //  console.log('setFormData: ', formData);
      } else {
        const datetime = dayjs(formData.nextcalldatetime);

        if (datetime.isBefore(dayjs(), 'day')) {
        //  console.log('Verilen tarih bugünden önce.');
          errors.nextcalldatetime = true;
          firstError = firstError || 'nextcalldatetime';
          showMessage(`The selected date (${firstError}) field should not be before today!`, 'warning');
        } else if (datetime.isAfter(dayjs(), 'day')) {
        //  console.log('Verilen tarih bugünden sonra.');
        } else {
        //  console.log('Verilen tarih bugün.');     
        }

      }
    }
    setFormErrors(errors);
    setFirstErrorField(firstError);

    if (firstError) {
      const refs = {
        contactstate: contactstateRef,
        contactchannel: contactchannelRef,
        assessmentlevel: assessmentlevelRef,
        newaction: newactionRef,
        nextcalldatetime: nextcalldatetimeRef
      };
      refs[firstError].current?.focus();
      showMessage(`Fill in (${firstError}) the required fields!`, 'warning');
    }

    return Object.keys(errors).length === 0;
};

  const updatedFormData = {
    ...formData,
  };

  const handleSubmit = async() => {
    const isValid = validateForm();
    if (!isValid) return;
    try {
      showLoading();
      setFormData(updatedFormData);
      const result = await createInterviewTransaction(formData);
    //  console.log('createInterviewTransaction-result: ', result);
      if(result.affectedRows === 1 && result.message === "New transaction created successfully."){
        showMessage(t('NewTransactionCreateSuccess'), 'success');
        setMsgOpen(true);
        onTransactionAdded();
        handleClose();
        if(showNewTask){
          setOpenConfirmation(true);
        }
        // TODO: Contact icin bildirim iletisi atilsin mi?
      } else {
        showMessage(result.message, 'error');
      }          
    } catch (error) {
      console.log("Error-handleSubmit: ", error);
    }finally{
      hideLoading();
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    clearForm();
  };

  const handleSendMailConfirmation = () => {
    // Simulate sending an email
    sendEmail(email);

    // Close the confirmation dialog
    setOpenConfirmation(false);

    // Clear the form after submission
    clearForm();
  };

  const sendEmail = (email) => {
    // Simulate sending an email with the next call details
   // console.log(`Email sent to ${email} with the next call date and time: ${nextCall}`);
  };
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const handleCloseSelectedTask = () => {
    setOpenSelectedTaskConfirmation(false);
    clearForm();
  };

  const handleRelatedSelectedTask = () => {
    const idtransaction = 1;
    relatedTask(idtransaction);
    setOpenSelectedTaskConfirmation(false);
    clearForm();
  };

  const relatedTask = (idtransaction) => {
   // console.log('idtransaction: ', idtransaction);
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false); 
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      // Set error message if not valid.
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };

  const handleRadioChange1 = (event) => {
    setFormData({ ...formData, radioGroup1: event.target.value });
  };

  const handleRadioChange2 = (event) => {
    setFormData({ ...formData, radioGroup2: event.target.value });
  };

  const handleCoordinatorName = (newValue) => {
    if (!newValue) {
      setCoordinatorName('');
      return;
    }
   // console.log('setCoordinatorName(newValue.id); :', newValue.id);
    setCoordinatorName(newValue.id);
  };

  const handleCheckboxChange = (event) => {
    setShowNewTask(event.target.checked);
  };

  return (
    <Grid sx = {{ml: 8}}>
      {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen} >
        {t('NewTransaction')}
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{color: "red"}}>{t('Title.CNT')}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, pb:2, pt:1, mr: 2 }}>
            {currentTime}
          </Typography>
          <TextField
            margin="dense"
            name="surname"
            label={t('TransactionBy')}
            fullWidth            
            variant="outlined"
            value={account.user[0].name_surname}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="contactstate-select-label">{t('ContactState')}</InputLabel>
            <Select
              labelId="contactstate-select-label"
              name="contactstate"
              label={t('ContactState')}
              value={formData.contactstate || ''}
              onChange={handleChangeContactState}
              className={formErrors.contactstate ? "blinking" : ""}
            >
              {contactState && contactState.map((contactState) => (                                
                <MenuItem key={contactState.idcontactstate} value={contactState.idcontactstate}>
                  {contactState.statename}        
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
              <InputLabel id="contactchannel-select-label">Contact Channel</InputLabel>
              <Select
                inputRef={contactchannelRef}
                labelId="contactchannel-select-label"
                name="contactchannel"
                label="Contact Channel"
                value={formData.contactchannel}
                onChange={handleContactChannels}                
                className={formErrors.contactchannel ? "blinking" : ""}
              >                
                {contactChannel && contactChannel.map((contactChannel) => (                                
                <MenuItem key={contactChannel.idcontactchannel} value={contactChannel.idcontactchannel}>
                  {contactChannel.contactenvironment}        
                </MenuItem>
              ))}
              </Select>
          </FormControl>          
          <FormControl fullWidth margin="dense">
              <InputLabel id="assessmentlevel-select-label">Assessment Level</InputLabel>
              <Select
                inputRef={assessmentlevelRef}
                labelId="assessmentlevel-select-label"
                name="assessmentlevel"
                label="Assessment Level"
                value={formData.assessmentlevel}
                onChange={handleChangeAssessmentLevel}                
                className={formErrors.contactchannel ? "blinking" : ""}
              >                
                {assessmentLevel && assessmentLevel.map((assessmentLevel) => (                                
                  <MenuItem key={assessmentLevel.idassesmentlevel} value={assessmentLevel.idassesmentlevel}>
                    {assessmentLevel.assesmentlevel}        
                  </MenuItem>
                ))}
              </Select>
              {formData.assessmentlevel === 7 && ( //TODO: must be dynamic (Burada Student secildigi zaman anlaminda id=7 kullandim.)
                <Box mt={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Choose an Option</FormLabel>
                    <RadioGroup
                      aria-label="option1"
                      name="radioGroup1"
                      value={formData.radioGroup1}
                      onChange={handleRadioChange1}
                    >
                      <FormControlLabel value="Job Center" control={<Radio />} label="Job Center" />
                      <FormControlLabel value="Private" control={<Radio />} label="Private" />
                    </RadioGroup>
                  </FormControl>
                  {formData.radioGroup1 === 'Job Center' && (
                    <Box mt={2}>
                      <FormControl component="fieldset">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={allcoordinators ? allcoordinators.map(coordinator => ({
                            id: coordinator.id,
                            label: coordinator.name_surname
                          })) : []}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          sx={{ flexGrow: 1,  width: 'auto', minWidth: 250, maxWidth: 500  }}
                          renderInput={(params) => <TextField {...params} label="Search Coordinator" inputRef={autocompleteCoordinatorRef} />}
                          onChange={(event, newValue) => {
                            handleCoordinatorName(newValue); // `newValue` seçilen değerdir. Bu, bir obje olabilir.
                          }}
                          ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
                          renderOption={(props, option, { selected }) => (
                            <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                              {option.label}
                            </Box>
                          )}
                        />
                      </FormControl>
                    </Box>
                  )}
                  {formData.radioGroup1 === 'Private' && (
                    <Box mt={2}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Choose a Mode</FormLabel>
                        <RadioGroup
                          aria-label="option2"
                          name="radioGroup2"
                          value={formData.radioGroup2}
                          onChange={handleRadioChange2}
                        >
                          <FormControlLabel value="Online" control={<Radio />} label="Online" />
                          <FormControlLabel value="LMS Hybrid" control={<Radio />} label="LMS Hybrid" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              )}
          </FormControl>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={showNewTask} onChange={handleCheckboxChange} />}
              label="Create New Task"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: showNewTask ? 'bold' : 'normal',
                  color: showNewTask ? 'mycolor.main' : 'inherit',
                },
              }}
            />
            {showNewTask && (
              <Box sx={{ border: '1px solid gray', padding: 2, marginTop: 0, marginBottom: 1, borderRadius: 2  }}>
                <TextField
                  margin="dense"
                  name="newaction"
                  label="New Action"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.newaction}
                  onChange={handleChange}
                  className={formErrors.newaction ? "blinking" : ""}
                  multiline
                  rows={3}
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="Next Call Date&Time"
                      value={nextCall}
                      onChange={handleNextCallChange}
                      className={formErrors.nextcalldatetime ? "blinking" : ""}
                      defaultValue={dayjs()}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            )}
          </Box>
          <TextField
            margin="dense"
            name="description"
            label={t('Description')}
            type="text"
            fullWidth
            variant="outlined"
            value={formData.descriptionabout}
            onChange={handleChangeDescription}
            multiline
          />                    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor:'blue',
              color: '#FFFFFF',
            }}>{t('Create')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        PaperProps={{
          sx: {
            padding: 2,
            borderRadius: 3,
            boxShadow: 5,
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description" sx={{ textAlign: 'center', marginBottom: 2 }}>
            <Typography variant="body1">
              An email will be sent to <strong>{email}</strong> with the next call date and time:
            </Typography>
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              {nextCall ? nextCall.format('MMMM D, YYYY h:mm A') : ''}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Do you confirm?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={() => handleCloseConfirmation(false)} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSendMailConfirmation} color="primary" variant="contained" sx={{ marginLeft: 2 }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    


      {alertMessages.map((msg, index) => (
        <Snackbar
          key={msg.id} 
          open={msgOpen} 
          autoHideDuration={6000} 
          onClose={() => {
            setAlertMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx = {{
            position: 'fixed', // Snackbar'ı sabitlemek için position fixed kullanılıyor.
            bottom: `${index * 120}px`, // Her mesaj için alttan yukarıya doğru 60px artan bir yer kaplar.
            left: 0, // Ekranın sol tarafından başlar.
            right: 0, // Ekranın sağ tarafına kadar uzanır.
            width: 'auto' // Genişlik otomatik ayarlanır.
          }}
        >
          <Alert severity={msg.severityType}>
            {msg.message}
          </Alert>
        </Snackbar>
      ))}
    </Grid>
  );
};