import axios from 'axios';
import getToken from '../../api/getToken';
import config from '../../../config';
const { BaseUrl } = config;

const snapshotRollback = async (node, vmid, snapname) => {
    try {
        // console.log("node, vmid, snapname: ", node, vmid, snapname);
        const token = await getToken();
        const response = await axios.post(`${BaseUrl}/nodes/qemu/snapshot/rollback`, {            
                node: node, 
                vmid: vmid,
                snapname: snapname
            },    
            {     
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );  
        // console.log("setRollBack: ", response);
        return response.data;
    } catch (error) {
        console.log("Error[setRollBack]: ", error);
        return error;
    }
};

export default snapshotRollback;