import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getEmployeeLeavesById = async (idUser) => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/hr/getemployeeleavesbyid`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params:{
          'userid': idUser
        }
      });      
      const info = response.data.data || [];
      return info;
  } catch (error) {
    console.error('Error fetching employee leaves by id:', error);
    return [];
  }
};

export { getEmployeeLeavesById };

