import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, SvgIcon, 
Grid, Autocomplete, Button, Box, TextField, FormControlLabel, Checkbox, Card, CardContent } from '@mui/material';
import { ReactComponent as Icon_Attendance } from '../../../assets/icons/attendance.svg';
// component
import Iconify from '../../../components/iconify';
//Multi Language
import { useTranslation } from 'react-i18next'; 
import getStatus from '../../../_data/student/getStatus';
//import DynamicSelect from '../../../components/select/dynamicSelect';
import { getBatchNames } from '../../../_data/batchs/getBatchNames';
import { getAllTags } from '../../../_data/mailchimp/getAllTags';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

StudentListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  statusName: PropTypes.string,
  onStatusName: PropTypes.func,
  BatchName: PropTypes.string,
  onBatchName: PropTypes.func,
};

export default function StudentListToolbar({ numSelected, filterName, onFilterName, onTags, onOpenRate, onClickRate }) {
  const { t } = useTranslation();
  const autocompleteStatusRef = useRef(null);
  const autocompleteBatchRef = useRef(null);
  const autocompleteTagsRef = useRef(null);
  const [statuses, setStatuses] = useState('');
  const [selectedStatusTitle, setSelectedStatusTitle] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [allstatuses, setAllStatuses] = useState([]); // List of All Statuses
  const [selectData, setSelectData] = useState();
  const [titleLabel, setTitleLabel] = useState("Search Course Name");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [page, setPage] = useState(0);
  const [filterStudentId, setfilterStudentId] = useState('');
  const [selectedBatchValue, setSelectedBatchValue] = useState('');  
  const [alltags, setAllTags] = useState([]);  
  const [checked, setChecked] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [clickRate, setClickRate] = useState(false);

  useEffect(() => {
    loadAllTags();
  }, []);

  const loadAllTags = async () => {
    try {
       const response = await getAllTags();
       const data = Array.isArray(response) ? response : []; 
       setAllTags(data);
    } catch (error) {
        console.error('Error loading tags:', error);
    }
  };  

  const handleSelectStatus = (status) => {
    if (statuses.indexOf(status.label) === -1) {
      setStatuses(statuses + (status.length > 0 ? ", " : "") + status.label);
    }
  };

  const handleBatchChange = (event, newValue) => {
    setClickRate(newValue);
    if (newValue) {
      onClickRate(newValue);
    } else {
      onClickRate(null);
    }
  };

  const handleOpenRateLimit = (event) => {
    console.log('handleCheck: ', event.target.checked);
    if(event.target.checked){
      setOpenRate(false);
      onOpenRate(false);
    }
    setOpenRate(event.target.checked);
    onOpenRate(event.target.checked);
  };

  // Manuel options
  const options = [
    { label: '1%', value: 1 },
    { label: '5%', value: 5 },
    { label: '10%', value: 10 },
    { label: '20%', value: 20 },
    { label: '50%', value: 50 },
    { label: '70%', value: 70 },
    { label: '100%', value: 100 }
  ];

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('CheckBoxSelected')}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <StyledSearch
              value={filterName}
              onChange={onFilterName}
              placeholder={t('SearchStudent')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}>          
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={alltags?.length > 0  ? alltags.map((tag, index) => ({
                id: tag.idmailchimp_tags,
                label: tag.mailchimp_tags,
                index: index 
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Search Tags" inputRef={autocompleteTagsRef} />}
              onChange={(event, newValue) => {
                onTags(newValue);
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
              renderOption={(props, option, { selected }) => (
                <Box 
                  component="li" 
                  key={option.index} 
                  sx={{ '& > button': { mr: 2 }, fontWeight: 'normal' ,color: '#E90074' }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options} // Manuel değerler
              getOptionLabel={(option) => option.label} // Seçim için gösterilecek etiket
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Select Min Open Rate" />}
              //onChange={handleChange} // Değişikliklerde filtreleme işlemi
              onChange={(event, newValue) => {
                onOpenRate(newValue);
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // Listbox için maxHeight ayarı
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options} // Manuel değerler
              getOptionLabel={(option) => option.label} // Seçim için gösterilecek etiket
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Select Min Click Rate" />}
              //onChange={handleChange} // Değişikliklerde filtreleme işlemi
              onChange={(event, newValue) => {
                onClickRate(newValue);
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // Listbox için maxHeight ayarı
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid>
          

          {/* <Grid item xs={2}>
            <Autocomplete
              disablePortal
              id="combo-box-batch"
              options={(selectData && Array.isArray(selectData))  ? selectData?.map(batch => ({
                id: batch.batchname,
                label: batch.batchname
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label={t('SearchCourseName')} inputRef={autocompleteBatchRef} />}
              onChange={handleBatchChange}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
              renderOption={(props, option, { selected }) => (
                <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid> */}

          {/* <Grid item xs={3}>          
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allstatuses ? allstatuses.map(status => ({
                id: status.idstudent_status,
                label: status.status_name
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Search Status" inputRef={autocompleteStatusRef} />}
              onChange={(event, newValue) => {
                onStatusName(newValue); // `newValue` seçilen değerdir. Bu, bir obje olabilir.
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
              renderOption={(props, option, { selected }) => (
                <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid> */}
        </Grid>        
      )}
      {numSelected > 1 ? (
        <Tooltip title={t('Attendances')}>
          <IconButton>
            <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('FilterList')}>
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}