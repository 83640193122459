import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getAllTags = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/mailchimp/getalltags`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
    console.log("getAllTags-Error: ", error);
    return [];
  }
};


export { getAllTags};