import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardContent, Typography, Grid, Box, CircularProgress,
Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField  } from '@mui/material';
import { getEmployeeLeaves } from '../../_data/hr/getEmployeeLeaves';
import { setEmployeeLeaves } from '../../_data/hr/setEmployeeLeaves';
import MessageBox from '../../utils/contexts/MessageBox';
import { Height } from '@mui/icons-material';

const EmployeeLeavesPage = () => {
  const [leaveData, setLeaveData] = useState([]);
  const [summary, setSummary] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const today = new Date();
  const annualLeaveDays = 15; // Kullanıcının yıllık izin hakkı
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [currentRow, setCurrentRow] = useState(null);

  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseMessage = () => {
    setMsgOpen(false);
  };

  // Fetch data from Database
  useEffect(() => {    
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await getEmployeeLeaves();
      const summary = { 
        onLeave: data.filter((leave) => {
          const startDate = new Date(leave.start_date); // İzin başlangıç tarihi
          const endDate = new Date(leave.end_date); // İzin bitiş tarihi
          return leave.status === 'Approved' && today >= startDate && today <= endDate; // Bugün izin tarihleri arasındaysa
        }).length,
        pending: data.filter((leave) => leave.status === 'Pending').length,
        approved: data.filter((leave) => leave.status === 'Approved').length,
        returningTomorrow: data.filter((leave) => {
          const endDate = new Date(leave.end_date); // `end_date` alanını al
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1); // Bugünün üzerine bir gün ekle
          return leave.status === 'Approved' && endDate.toDateString() === tomorrow.toDateString(); // Bitiş tarihi yarın mı kontrol et
        }).length,
      };
      setLeaveData(data);
      setSummary(summary);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const columns = [
    // { field: 'id_employee_leaves', headerName: 'ID', flex: 1, minWidth: 70, align: 'center', headerAlign: 'center', },
    { field: 'name_surname', headerName: 'Employee', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'leave_name', headerName: 'Leave Type', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    {
      field: 'start_date',
      headerName: 'Start Date',
      flex: 2,
      minWidth: 140,
      align: 'center', headerAlign: 'center',
      valueGetter: (params) => {
        if (!params) return ''; // Eğer row undefined/null ise boş string döndür
        return params || ''; // start_date alanına eriş
      },
      valueFormatter: (params) => {
        if (!params) return 'N/A'; // Eğer value yoksa "N/A" göster
        const date = new Date(params);
        if (isNaN(date.getTime())) return 'Invalid Date'; // Eğer geçerli bir tarih değilse
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}/${date.getFullYear()}`;
      },
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      flex: 2,
      minWidth: 140,
      align: 'center', headerAlign: 'center',
      valueGetter: (params) => {
        if (!params) return '';
        return params || '';
      },
      valueFormatter: (params) => {
        if (!params) return 'N/A';
        const date = new Date(params);
        if (isNaN(date.getTime())) return 'Invalid Date';
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}/${date.getFullYear()}`;
      },
    },    
    { field: 'total_days', headerName: 'Total Days', flex: 1 ,minWidth: 100 },
    { field: 'reason', headerName: 'Leave Explanation', flex: 2 ,minWidth: 140 },
    { field: 'rejection_reason', headerName: 'Reject Explanation', flex: 2 ,minWidth: 140 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1, 
      align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <span
          style={{
            color:
              params.value === 'Approved'
                ? 'green'
                : params.value === 'Rejected'
                ? 'red'
                : 'orange',
            fontWeight: 'bold',
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      minWidth: 200,
      align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <div>
          <button
            onClick={() => handleApprove(params.row)}
            style={{ marginRight: '10px', color: 'green', cursor: 'pointer' }}
          >
            Approve
          </button>
          <button
            // onClick={() => handleReject(params.row)}
            onClick={() => handleOpenRejectDialog(params.row)}
            style={{ color: 'red', cursor: 'pointer' }}
          >
            Reject
          </button>
        </div>
      ),
    },
  ];

  const handleApprove = async (row) => {
    try {
      const response = await setEmployeeLeaves(row.userid, 'Approved', row.id_employee_leaves, rejectReason);
      if (response.status === 200) {
        showMessage('The record has been updated  as Approved successfully.', 'success');        
        fetchData();
      } else {
        showMessage('Failed, The record has not been updated as Approved!', 'error');
      }
    } catch (error) {
      console.error('Error approving leave:', error);
    }finally{
      setRejectReason('');
    }
  };

  const handleOpenRejectDialog = (row) => {
    setCurrentRow(row);
    setRejectReason('');
    setRejectDialogOpen(true);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialogOpen(false);
    setRejectReason('');
  };
  
  const handleReject = async (row) => {
    if (!currentRow) return;
    try {
      console.log("rejectReason: ", rejectReason);
      const response = await setEmployeeLeaves(currentRow.userid, 'Rejected', currentRow.id_employee_leaves, rejectReason);
      //const response = await setEmployeeLeaves(row.userid, 'Rejected', row.id_employee_leaves);       
      if (response.status === 200) {
        showMessage('The record has been updated as Rejected successfully.', 'success');
        fetchData();// Refresh DataGrid        
      } else {
        showMessage('Failed, The record has not been updated as Rejected!', 'error');
      }
    } catch (error) {
      console.error('Error rejecting leave:', error);
    }finally{
      setRejectReason('');
      handleCloseRejectDialog();
    }
  };

  // Kullanıcının yıllık izin bilgilerini hesapla
  const calculateLeaveDetails = () => {
    if (!selectedRow) return { totalUsed: 0, remaining: annualLeaveDays };
    const currentYear = today.getFullYear();
    const userLeaves = leaveData.filter(
      (leave) =>
        leave.userid === selectedRow.userid &&
        new Date(leave.start_date).getFullYear() === currentYear &&
        leave.status === 'Approved'
    );
    const totalUsed = userLeaves.reduce((sum, leave) => sum + leave.total_days, 0);
    const remaining = Math.max(annualLeaveDays - totalUsed, 0);
    return { totalUsed, remaining };
  };

  const { totalUsed, remaining } = calculateLeaveDetails();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      {/* Summary Cards */}
      <Grid container spacing={2} mb={3}>
        {[
          selectedRow
          ? {
              title: selectedRow.name_surname,
              value: (
                <>
                  <Typography variant="body1" align="center">
                    Total Leaves: {totalUsed} days
                  </Typography>
                  <Typography variant="body1" align="center" mt={1}>
                    Leave Days: {remaining} days
                  </Typography>
                </>
              ),
            }
          : { title: "Select an Employee", value: "No Data" }, // Eğer satır seçili değilse
          { title: 'Currently On Leave', value: summary.onLeave || 0 },
          { title: 'Returning Tomorrow', value: summary.returningTomorrow || 0 },
          { title: 'Pending Requests', value: summary.pending || 0 },
          { title: 'Approved Leaves', value: summary.approved || 0 },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <Card
              sx={{
                  borderRadius: 2,
                  boxShadow: 3,
                  backgroundColor: "#f5f5ff",
                  height: "150px", // Sabit yükseklik
                  display: "flex", // İçeriği ortalamak için flex kullanımı
                  flexDirection: "column",
                  justifyContent: "center", // Dikeyde ortalama
                  alignItems: "center", // Yatayda ortalama
              }}
            >
              <CardContent >
                <Typography variant="h6" color="textSecondary" align='center'>
                  {item.title}
                </Typography>
                <Typography variant="h4" color="primary" align='center' mt={2}>
                  {item.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>      
      {/* Leave Requests Table */}
      <Box p={3} style={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Leave Requests
        </Typography>
        <DataGrid
          rows={leaveData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          autoHeight
          checkboxSelection
          onRowClick={(params) => setSelectedRow(params.row)} // Seçili satır bilgisi
          getRowId={(row) => row.id_employee_leaves}
          //disableColumnResize //kullanicinin tablo alanlarinin boyutunun degistirmesini engeller yazilmaz ise degistirebilir.
        />
      </Box>

      <Dialog open={rejectDialogOpen} onClose={handleCloseRejectDialog}>
        <DialogTitle>Reject Leave</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Rejection"
            multiline
            rows={4}
            fullWidth
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            inputProps={{ maxLength: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleReject} color="primary" disabled={!rejectReason.trim()}>
            Reject
          </Button>
        </DialogActions>
      </Dialog>



      <MessageBox
        msgOpen={msgOpen}
        severity={severity}
        alertMessage={alertMessage}
        handleCloseMessage={handleCloseMessage}
      />
    </Box>    
  );
};

export default EmployeeLeavesPage;