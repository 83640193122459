import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, SvgIcon, 
Grid, Autocomplete, Divider, Box, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { ReactComponent as Icon_Attendance } from '../../../assets/icons/attendance.svg';
// component
import Iconify from '../../../components/iconify';
//Multi Language
import { useTranslation } from 'react-i18next'; 
import getStatus from '../../../_data/student/getStatus';
import { getAllLeadFormNames, getAllAdNames, getAllParticipantCountries } from '../../../_data/facebook/getLeadFormDatas';
import { getAllCampaigns } from '../../../_data/facebook/getAllCampaigns';
import { getAllAdSets } from '../../../_data/facebook/getAllAdSets';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

MetaAllLeadParticipantsListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  adName: PropTypes.string,
  onAdName: PropTypes.func,
  leadName: PropTypes.string,
  onLeadFormsName: PropTypes.func,
  startDate: PropTypes.string,
  onStartDate: PropTypes.func,
  endDate: PropTypes.string,
  onEndDate: PropTypes.func
};

export default function MetaAllLeadParticipantsListToolbar({ numSelected, filterName, onFilterName, onLeadFormsName, 
  onAdsName, onAdSetsName, onCampaignsName, onStartDate, onEndDate, onEmptyHide, onCountry }) {
  const { t } = useTranslation();
  const autocompleteCountryRef = useRef(null);
  const autocompleteLeadFormRef = useRef(null);
  const autocompleteAdIdsRef = useRef(null);
  const autocompleteAdSetIdsRef = useRef(null);
  const autocompleteCampaignsRef = useRef(null);
  const [selectData, setSelectData] = useState();
  const [page, setPage] = useState(0);
  const [filterStudentId, setfilterStudentId] = useState('');
  const [adIds, setAdIds] = useState([]);  
  const [campaignDatas, setCampaignDatas] = useState([]);  
  const [allAdSetDatas, setAllAdSetDatas] = useState([]);  
  const [selectedAdIds, setSelectedAdIds] = useState([]);  
  const [leadForms, setLeadForms] = useState([]);  
  const [selectedLeadForms, setSelectedLeadForms] = useState([]);  
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState(null); // Başlangıç tarihi
  const [endDate, setEndDate] = useState(null);     // Bitiş tarihi
  const [participantCountries, setParticipantCountries] = useState();

  useEffect(() => {
    loadCampaignIds();
    loadAdSetIds();
    loadAdIds();
    loadLeadForms();
    loadParticipantCountries();
  }, []);

  const loadCampaignIds = async () => {
    try {
       const response = await getAllCampaigns();
       const data = Array.isArray(response) ? response : [];
       setCampaignDatas(data);
    } catch (error) {
        console.error('Error loading load Campaign Names:', error);
    }
  };

  const loadAdSetIds = async () => {
    try {
       const response = await getAllAdSets();
       const data = Array.isArray(response) ? response : [];
       setAllAdSetDatas(data);
    } catch (error) {
        console.error('Error loading load Advert Set Names:', error);
    }
  };

  const loadAdIds = async () => {
    try {
       const response = await getAllAdNames();
       const data = Array.isArray(response) ? response : [];
       setAdIds(data);
    } catch (error) {
        console.error('Error loading load Lead Form Names:', error);
    }
  };

  const loadLeadForms = async () => {
    try {
       const response = await getAllLeadFormNames();
       const data = Array.isArray(response) ? response : [];
       setLeadForms(data);
    } catch (error) {
        console.error('Error loading load Lead Form Names:', error);
    }
  };  

  const loadParticipantCountries = async () => {
    try {
       const response = await getAllParticipantCountries();
       const data = Array.isArray(response) ? response : [];
       setParticipantCountries(data);
    } catch (error) {
        console.error('Error loading load Participant Countries:', error);
    }
  };  

  // const handleSelectionChange = (value) => { 
  //   console.log('onLeadFormsName: ', value);
  //   if (value) {
  //     onLeadFormsName(value); // Seçilen lead form ID'yi üst bileşene ilet
  //   }else {
  //     onLeadFormsName(null); // Seçim yoksa sıfırla
  //   }
  // };

  // const handleSelectionAdChange = (value) => { 
  //   console.log('onAdsName: ', value);
  //   if (value) {
  //     onAdsName(value); // Seçilen lead form ID'yi üst bileşene ilet
  //   }else {
  //     onAdsName(null); // Seçim yoksa sıfırla
  //   }
  // };

  // const handleLeadChange = (event, newValue) => {
  //   setSelectedLeadForms(newValue);
  //   if (newValue) {
  //     onLeadFormsName(newValue);
  //   } else {
  //     onLeadFormsName(null);
  //   }
  // };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue); // Seçilen başlangıç tarihi
    if (newValue) {
      onStartDate(newValue);
    } else {
      onStartDate(null);
    }
  };
  
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue); // Seçilen bitiş tarihi
    if (newValue) {
      onEndDate(newValue);
    } else {
      onEndDate(null);
    }
  };

  // const handleCampaignsNameChange = (newValue) => {
  //   setStartDate(newValue); // Seçilen başlangıç tarihi
  //   if (newValue) {
  //     onStartDate(newValue);
  //   } else {
  //     onStartDate(null);
  //   }
  // };

  // const handleHide = (isChecked) => {
  //   if (isChecked) {
  //     onEmptyHide(true);
  //     // Logic to hide empty records
  //     console.log("Hide empty records");
  //   } else {
  //     onEmptyHide(false);
  //     // Logic to show all records
  //     console.log("Show all records");
  //   }
  // };

  return (
    <>
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
          }),
          height: 'auto',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Satırlar arası boşluk
          marginBottom: 1, // Alt kısımdan boşluk vererek tablo başlığından ayırma
          borderRadius: 1,
          backgroundColor: 'white', // Arka planı beyaz yaparak tabloyla kontrast oluşturma
          overflow: 'visible', // İçerideki elemanların taşmasını önlemek için
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} {t('CheckBoxSelected')}
          </Typography>
        ) : (
          <>
            <Box sx={{ width: '100%'}}>
              <Grid container spacing={2} alignItems="center" sx={{mb: 3}}>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="adverts-combo-box"
                    options={campaignDatas?.length > 0 ? campaignDatas?.map((campaigns) => ({
                      id: campaigns.id,
                      label: campaigns.name,
                      key: campaigns.id,
                    })) : []}
                    getOptionLabel={(option) => `${option.label} - ${option.id}`}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => <TextField {...params} label="Search Campaign Name" inputRef={autocompleteCampaignsRef} />}
                    onChange={(event, newValue) => onCampaignsName(newValue)}
                    ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                    renderOption={(props, option) => (
                      <Box
                        key={option.key}
                        component="li"
                        sx={{ '& > button': { mr: 2 }, fontWeight: 'normal', color: 'navy' }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="adverts-combo-box"
                    options={allAdSetDatas?.length > 0 ? allAdSetDatas?.map((adSetData) => ({
                      id: adSetData.id,
                      label: adSetData.name,
                      key: adSetData.id,
                    })) : []}
                    getOptionLabel={(option) => `${option.label} - ${option.id}`}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => <TextField {...params} label="Search Advert Sets" inputRef={autocompleteAdSetIdsRef} />}
                    onChange={(event, newValue) => onAdSetsName(newValue)}
                    ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                    renderOption={(props, option) => (
                      <Box
                        key={option.key}
                        component="li"
                        sx={{ '& > button': { mr: 2 }, fontWeight: 'normal', color: 'navy' }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="adverts-combo-box"
                    options={adIds?.length > 0  ? adIds.map((adsData) => ({
                      id: adsData.id,
                      label: adsData.name,
                      key: adsData.id,
                    })) : []}
                    getOptionLabel={(option) => `${option.label} - ${option.id}`}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => <TextField {...params} label="Search Advert Name" inputRef={autocompleteAdIdsRef} />}
                    onChange={(event, newValue) => onAdsName(newValue)}
                    ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                    renderOption={(props, option) => (
                      <Box
                        key={option.key}
                        component="li"
                        sx={{ '& > button': { mr: 2 }, fontWeight: 'normal', color: 'navy' }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="lead-forms-combo-box"
                    options={leadForms?.length > 0 ? leadForms?.map((leadForm) => ({
                      id: leadForm.id,
                      label: leadForm.name,
                      key: leadForm.id,
                    })) : []}
                    getOptionLabel={(option) => `${option.label} - ${option.id}`}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => <TextField {...params} label="Search Lead Forms" inputRef={autocompleteLeadFormRef} />}
                    onChange={(event, newValue) => onLeadFormsName(newValue)}
                    ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                    renderOption={(props, option) => (
                      <Box
                        key={option.key}
                        component="li"
                        sx={{ '& > button': { mr: 2 }, fontWeight: 'normal', color: '#E90074' }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
              {/* <Divider sx={{ my: 1 }} />  */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <StyledSearch
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={t('SearchStudent')}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="adverts-combo-box"
                    options={participantCountries?.length > 0 ? participantCountries?.map((country, index) => ({
                      id: `country-${index}`,
                      label: country.name,
                      // key: `country-${index}`,
                    })) : []}
                    getOptionLabel={(option) => `${option.label}`}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => <TextField {...params} label="Search Country Name" inputRef={autocompleteCountryRef} />}
                    onChange={(event, newValue) => onCountry(newValue)}
                    ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                    renderOption={(props, option) => (
                      <Box
                        key={option.id}
                        component="li"
                        sx={{ '& > button': { mr: 2 }, fontWeight: 'normal', color: 'navy' }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      slots={{ textField: (props) => <TextField {...props} /> }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      slots={{ textField: (props) => <TextField {...props} /> }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => onEmptyHide(e.target.checked)}
                      />
                    }
                    label="Hide Empty Records"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </StyledRoot>
    </>
  );
}