import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getCoordinatorsComplatedTotalTasks = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/students/getcoordinatorscomplatedtotaltasks`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const TotalComplatedTask = response.data || [];
    //console.log('getCoordinatorsComplatedTotalTasks: ', TotalComplatedTask);
    return TotalComplatedTask;
   // return 0;
  } catch (error) {
    console.error('Error getCoordinatorsComplatedTotalTasks Records: ', error);
    return [];
  }
};
export default getCoordinatorsComplatedTotalTasks;