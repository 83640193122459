import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setEmployeeLeaves = async (id_employee_leaves, result_update, id, rejectReason) => {
  try {
    const token = await getToken();
    const response = await axios.patch(`${BaseUrl}/hr/setemployeeleavesbyid`, 
      { userid: id_employee_leaves, result_update: result_update, id: id, reject_reason: rejectReason},
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
    });
    const info = response || [];
    return info;
  } catch (error) {
      return [];
  }
};

export { setEmployeeLeaves };

