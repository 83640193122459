import React, { useEffect, useState } from "react";
import { Box, Button, Container, MenuItem, TextField, Typography } from "@mui/material";
import { getLeaveTypes } from "../../_data/hr/getLeaveTypes";
import { getEmployeeBereavementLeavesTypes } from "../../_data/hr/getEmployeeBereavementLeavesTypes";
import MessageBox from '../../utils/contexts/MessageBox';

const LeaveRequestForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    type: "",
    startDate: "",
    endDate: "",
    reason: "",
    bereavementOption: "",
  });

  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [showBereavementDropdown, setShowBereavementDropdown] = useState(false);
  const [leavesBereavementData, setLeavesBereavementData] = useState([]);

  // Fetch Bereavement Data
  const loadEmployeeBereavementLeavesTypes = async () => {
    try {
      const response = await getEmployeeBereavementLeavesTypes();
      setLeavesBereavementData(response.data || []); // Only use the 'data' field
    } catch (error) {
      console.error("Error fetching Bereavement leaves data: ", error);
      setLeavesBereavementData([]); // Set to empty array if error
    }
  };

  // Fetch Leave Types
  const loadLeavesTypes = async () => {
    try {
      const leaveTypes = await getLeaveTypes();
      setLeaveTypeData(leaveTypes.data || []);
    } catch (error) {
      console.log("Error-loadLeavesTypes: ", error);
    }
  };

  useEffect(() => {
    loadLeavesTypes();
    loadEmployeeBereavementLeavesTypes();
  }, []);

   // Handle Form Submit
   const handleSubmit = () => {
    let errorMessage = {Message: "", Status: ""};
    if (showBereavementDropdown && !formData.bereavementOption) {
      errorMessage = { Message: 'Please select a Bereavement Option.', Status: 'warning' };      
      onSubmit(formData, errorMessage);
      return;      
    }

    // Start Date seçilmediğinde hata kontrolü
    if (!formData.startDate) {
      errorMessage = {Message: "Please select a Start Date.", Status: "warning",};
      onSubmit(formData, errorMessage);
      return; // Hata olduğunda işlemi durdur
    }

    // Start Date, End Date'den sonraki bir tarih olduğunda hata kontrolü
    if (formData.endDate && new Date(formData.startDate) > new Date(formData.endDate)) {
      errorMessage = {Message: "Start Date cannot be after End Date.", Status: "warning",};
      onSubmit(formData, errorMessage);
      return; // Hata olduğunda işlemi durdur
    }

    // Hata yoksa
    onSubmit(formData, { Message: "", Status: "" });
    setFormData({ type: "", startDate: "", endDate: "", reason: "", bereavementOption: "" });
    setShowBereavementDropdown(false); // Bereavement Dropdown'u gizle
  };

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "type" && parseInt(value, 10) === 6) {
      setShowBereavementDropdown(true);
    } else if (name === "type") {
      setShowBereavementDropdown(false);
      setFormData((prev) => ({ ...prev, bereavementOption: "" })); // Reset bereavement option
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        <Typography variant="h6">Request Leave</Typography>
        {/* Leave Type Dropdown */}
        <TextField
          label="Leave Type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          select
          fullWidth
          margin="normal"
        >
          {leaveTypeData &&
            leaveTypeData.map((leaveType) => (
              <MenuItem key={leaveType.id} value={leaveType.id}
                sx={{
                  color: leaveType.leaves_days < 0 ? "green" : "black", // Dinamik renk kontrolü
                }}
              >
                {leaveType.leave_name} 
                {/* {parseFloat(leaveType.leaves_days) > 0 ? ` ${leaveType.leaves_days.toFixed(2)} days)` : ''} */}
              </MenuItem>
            ))}
        </TextField>

        {/* Bereavement Dropdown */}
        {showBereavementDropdown && (
          <TextField
            label="Bereavement Option"
            name="bereavementOption"
            value={formData.bereavementOption || ""}
            onChange={handleChange}
            select
            fullWidth
            margin="normal"
          >
            {leavesBereavementData.length > 0 ? (
              leavesBereavementData.map((option) => (
                <MenuItem key={option.idemployee_bereavement_level} value={option.idemployee_bereavement_level}>
                  {option.relation_type}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="">
                No options available
              </MenuItem>
            )}
          </TextField>
        )}

        {/* Start Date */}
        <TextField
          label="Start Date"
          name="startDate"
          type="date"
          value={formData.startDate}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        {/* End Date */}
        <TextField
          label="End Date"
          name="endDate"
          type="date"
          value={formData.endDate}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        {/* Reason */}
        <TextField
          label="Reason"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          fullWidth
          multiline
          rows={3}
          margin="normal"
        />

        {/* Submit Button */}
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Submit Request
        </Button>
      </Box>
    </Container>
  );
};

export default LeaveRequestForm;