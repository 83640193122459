import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getPreStudentCount = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/finance/getprestudentcount`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

export { getPreStudentCount };

