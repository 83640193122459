import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getLeadFormDatas = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getleadformdatas`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

const getAdsParticipant = async (lead_id) => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getleadformparticipants?lead_id=${lead_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

const getAllLeadFormParticipants = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getallleadformparticipants`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];      
      return info;
  } catch (error) {
      return [];
  }
};

const getAllLeadFormNames = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getallleadformnames`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];      
      return info;
  } catch (error) {
      return [];
  }
};

const getAllParticipantCountries = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getallparticipantcountries`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];      
      return info;
  } catch (error) {
      return [];
  }
};

const getAllAdNames = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getalladnames`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
    console.log("getAllAdNames-Error: ", error);
      return [];
  }
};


export { getLeadFormDatas, getAdsParticipant, getAllLeadFormParticipants, 
  getAllLeadFormNames, getAllAdNames, getAllParticipantCountries };