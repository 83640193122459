import React from 'react';
import { Container, Avatar, Typography, Box } from '@mui/material';

const StaffInfo = ({ staffInfo }) => {
  return (
    <Container maxWidth="800">
      <Box display="flex" flexDirection="column" alignItems="left">
        <Avatar src={staffInfo?.photourl} alt="Profile Picture" sx={{ width: 90, height: 90, mb: 1 }} />
        <Typography variant="h5" component="h1">{staffInfo?.name_surname}</Typography>
        <Typography color="textSecondary">{staffInfo?.username}</Typography>
        <Typography color="textSecondary">Job Title: {staffInfo?.jobs.title}</Typography>
        <Typography color="textSecondary">Department: {staffInfo?.jobs.Department}</Typography>
        <Typography color="textSecondary">Status: {staffInfo?.status_name === 'USER' ? 'Employee': staffInfo?.status_name}</Typography>
        {/* <Box mt={3}>
          <Typography variant="h6">Remaining Annual Leave: {user.leaveBalance.annual}</Typography>
          <Typography variant="h6">Sick Leave Left: {user.leaveBalance.sick}</Typography>
        </Box> */}
      </Box>
    </Container>
  );
};

export default StaffInfo;
