import React, { useState, useEffect } from 'react';
import { Stack, Typography, Card, CardContent, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import getCoordinatorsTotalTasks from '../../_data/student/getCoordinatorsTotalTasks';
import getCoordinatorsTodayTasks from '../../_data/student/getCoordinatorsTodayTasks';
import getCoordinatorsCompletedTodayTasks from '../../_data/student/getCoordinatorsCompletedTodayTasks';
import getCoordinatorsStudentRecords from '../../_data/coursecoordination/getCoordinatorsStudentRecords';
import getCoordinatorsCompletedTotalTasks from '../../_data/student/getCoordinatorsCompletedTotalTasks';

const GetCourseCoordinatorTasks = ({ selectedCoordinatorId, isRefresh }) => {
  //console.log('GetCourseCoordinatorTasks-isRefresh: ', isRefresh);
  const [getRefresh, setGetRefresh]=useState(isRefresh);
  const { t } = useTranslation();
  const [coordinators, setCoordinators] = useState([]);

  const loadCoordinatorsTasks = async () => {
    try {
      const [totalTasksResponse, todayTasksResponse, complatedTodayTasksResponse, totalFollowUpResponse, totalComletedTaskResponse] = await Promise.all([
        getCoordinatorsTotalTasks(),
        getCoordinatorsTodayTasks(),
        getCoordinatorsCompletedTodayTasks(),
        getCoordinatorsStudentRecords(),
        getCoordinatorsCompletedTotalTasks()
      ]);

      const mergedData = totalTasksResponse.map(totalTask => {
        const todayTask = todayTasksResponse.find(task => task.follower === totalTask.follower) || { taskcount: 0 };
        const complatedTodayTask = complatedTodayTasksResponse.find(complatedtask => complatedtask.follower === totalTask.follower) || { taskcount: 0 };
        const totalFollowUp = totalFollowUpResponse.find(student => student.follower === totalTask.follower) || { taskcount: 0 };
        const completedTotalTask = totalComletedTaskResponse.find(student => student.follower === totalTask.follower) || { taskcount: 0 };

        return {
          ...totalTask,
          taskTotalCount: totalTask.taskcount,
          taskTodayCount: todayTask.taskcount,
          taskComplatedTodayCount: complatedTodayTask.taskcount,
          totalStudentCount: totalFollowUp.taskcount,
          taskComplatedTotalCount: completedTotalTask.taskcount,

        };
      });

      setCoordinators(mergedData);
      setGetRefresh(false);
    } catch (error) {
      console.error('Error loading tasks:', error);
    }
  };

  useEffect(() => {
    loadCoordinatorsTasks();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      //  console.log('useEffect-isRefresh: ', isRefresh);
        loadCoordinatorsTasks();
    }
  }, [isRefresh]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Paper
        sx={{
          border: '1px solid #ddd',
          borderRadius: '16px',
          padding: '16px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          mt: '5px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: '3px',
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          Course Coordinators
        </Typography>
        <Grid container spacing={2}>
          {coordinators.map((coordinator, index) => (
            <Grid item key={index}>
              <Card 
                sx={{ 
                    borderRadius: '12px', 
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
                    border: selectedCoordinatorId === coordinator.follower ? '2px solid blue' : 'none' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                    {coordinator.follower_namesurname}
                  </Typography>
                  <Typography sx={{ color: '#d32f2f', mt: '2px' }}>
                    Today Task: <span style={{ color: '#9c27b0', fontWeight: 'bold' }}>{coordinator.taskTodayCount}</span>
                  </Typography>
                  <Typography sx={{ color: '#388e3c', mt: '2px' }}>
                    Today Completed: <span style={{ color: '#2e7d32', fontWeight: 'bold' }}>{coordinator.taskComplatedTodayCount}</span>
                  </Typography>
                  <Typography sx={{ color: '#1976d2', mt: '2px' }}>
                    Total Task: <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>{coordinator.taskTotalCount}</span>
                  </Typography>
                  <Typography sx={{ color: '#388e3c', mt: '2px' }}>
                    Total Complated: <span style={{ color: '#2e7d32', fontWeight: 'bold' }}>{coordinator.taskComplatedTotalCount}</span>
                  </Typography>
                  <Typography sx={{ color: '#000000', mt: '2px', fontWeight: 'bold'}}>
                    Candidate Records: <span style={{ color: '#000000', fontWeight: 'bold' }}>{coordinator.totalStudentCount}</span>
                    {/* Student Records: <span style={{ color: '#000000', fontWeight: 'bold' }}>X</span> */}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Stack>
  );
};

export default GetCourseCoordinatorTasks;