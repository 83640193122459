import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getRegisteredStudents = async () => {
  try {
      const token = await getToken();
      //console.log('getRegisteredStudents');
      const response = await axios.get(`${BaseUrl}/finance/getregiteredstudents`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const info = response.data || [];
      //console.log('getRegisteredStudents-response: ', info);
      return info;
  } catch (error) {
      return [];
  }
};

const isStudentChecked = async (id) => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/finance/checkstatusisstudent`, {        
        params: {
          idStudent: id // Query parametre olarak id'yi doğru şekilde gönderiyoruz
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

const getActiveFinanceTypes = async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/finance/getactivefinancetypes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
        return info;
    } catch (error) {
        return [];
    }
};

export { getRegisteredStudents, isStudentChecked, getActiveFinanceTypes };

