import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, SvgIcon, 
Grid, Autocomplete, Button, Box, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { ReactComponent as Icon_Attendance } from '../../../assets/icons/attendance.svg';
// component
import Iconify from '../../../components/iconify';
//Multi Language
import { useTranslation } from 'react-i18next'; 
import { getAllCampaigns } from '../../../_data/facebook/getAllCampaigns';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

StudentListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  campaignName: PropTypes.string,
  onCampaignName: PropTypes.func,
};

export default function StudentListToolbar({ numSelected, filterName, onFilterName, onCampaignName}) {
  const { t } = useTranslation();
  const autocompleteCampaignsRef = useRef(null);
  const [allCampaigns, setAllCampaigns] = useState([]); // List of All Statuses
  const [selectData, setSelectData] = useState();
  const [page, setPage] = useState(0);
  // const [filterStudentId, setfilterStudentId] = useState('');
  const [checked, setChecked] = useState(false);


  useEffect(() => {
    loadCampaigns();
  }, []);

  const loadCampaigns = async () => {
    try {
       const response = await getAllCampaigns();
       setAllCampaigns(response);
    } catch (error) {
        console.error('Error loading loadStatuses:', error);
    }
  };

  const handleSelectionCampaignsChange = (value) => { 
    console.log('onCampaignName: ', value);
    if (value) {
      onCampaignName(value); // Seçilen lead form ID'yi üst bileşene ilet
    }else {
      onCampaignName(null); // Seçim yoksa sıfırla
    }
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('CheckBoxSelected')}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <StyledSearch
              value={filterName}
              onChange={onFilterName}
              placeholder={t('SearchStudent')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disablePortal
              id="adverts-combo-box"
              options={allCampaigns ? allCampaigns?.map((campaignsData) => ({
                id: campaignsData.id,
                label: campaignsData.name,
                key: campaignsData.id,
              })) : []}
              getOptionLabel={(option) => `${option.label} - ${option.id}`}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Search Campaign Name" inputRef={autocompleteCampaignsRef} />}
              onChange={(event, newValue) => handleSelectionCampaignsChange(newValue)}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
              renderOption={(props, option) => (
                <Box
                  key={option.key}
                  component="li"
                  sx={{ '& > button': { mr: 2 }, fontWeight: 'normal', color: 'navy' }}
                  {...props}
                >
                  {option.label}
                </Box>
              )}
            />
          </Grid>
        </Grid>        
      )}
      {numSelected > 1 ? (
        <Tooltip title={t('Attendances')}>
          <IconButton>
            <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('FilterList')}>
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}