import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getMailchimpDatas = async () => {
  try {    
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/mailchimp/getmailchimpdatabaselist`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      //console.log("getMailchimpDatas: ", response.data );
      const info = response.data || [];
      return info;
  } catch (error) {
    console.log("getMailchimpDatas-Error: ", error);
    return [];
  }
};


export { getMailchimpDatas};