import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getAllAdSets = async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/facebook/getalladsets`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
        return info;
    } catch (error) {
      console.log("getAllAdSets-Error: ", error);
        return [];
    }
  };
  
  
  export { getAllAdSets };