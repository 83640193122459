import React from 'react';
import { Box, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
//import { colorToComponents } from 'pdf-lib';

const formatDate = (dateString) => {
  try {    
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));    
  } catch (error) {
    console.log("Error-formatDate: ", error);
  }
};

const LeaveStatus = ({ leaveRequests, leavesBereavementData }) => {
  return (
    <Box mt={4}>
      <Typography variant="h6">Leave History</Typography>
      <List>
        {leaveRequests.map((request) => (
          <React.Fragment key={request.id_employee_leaves}>
            <ListItem>
              <ListItemText
                // primary={`${request.leave_name} from ${formatDate(request.start_date)} to ${formatDate(request.end_date)}`}
                primary={
                  <>
                    {`${request.leave_name} from ${formatDate(request.start_date)} to ${formatDate(request.end_date)}`}
                    <br />
                    <span style={{ fontSize: "0.9rem", color: "gray" }}>{`Duration: ${request.total_days} days`}</span>                    
                  </>
                }
                secondary={
                  <>
                    {`Status: ${request.status}`}
                    {request.status === "Rejected" ? (
                      <span style={{ fontSize: "0.9rem", fontWeight: "normal", color: "red", display: "block" }}>
                        {`Reject Explanation: ${request.rejection_reason}`}
                      </span>
                    ) : null}
                  </>
                }
                primaryTypographyProps={{
                  style: { color: "black", fontWeight: "normal" }, // primary style
                }}
                secondaryTypographyProps={{
                  style: {
                    color: request.status === "Approved" ? "green" : request.status === "Pending" ? "orange": "red", // secondary style
                    fontStyle: "italic", fontWeight: "bold" // Italic style
                  },
                }}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default LeaveStatus;
