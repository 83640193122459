import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton,
  CircularProgress, Grid, Dialog, DialogTitle, DialogContent, Button, Box, Alert, Snackbar, DialogActions,
  DialogContentText } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import UndoIcon from '@mui/icons-material/Undo';
import ComputerIcon from '@mui/icons-material/Computer';
// import { deepOrange, amber, purple, orange, cyan, red, green } from '@mui/material/colors';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Divider from '@mui/material/Divider';
// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import { fDate, fDateX } from '../../utils/formatTime';

import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import LoadingButton from '@mui/lab/LoadingButton';
import { getSnapshots } from '../../_data/labs/lab1/getSnapshots';
import snapshotRollback from '../../_data/labs/lab1/snapshotRollback';
import { useConfirmDialog } from '../../utils/contexts/ConfirmDialogContext';


export default function SnapshotsDialog({ onTransactionAdded, node, vmid, openSnapshotDialog, onClose }) {
  const { showLoading, hideLoading } = useLoading();
  //console.log('node, vmid: ', node, vmid);
  const { t } = useTranslation();
  const account = getSession();
  const [open, setOpen] = useState(openSnapshotDialog);
  const [openSelectedTaskConfirmation, setOpenSelectedTaskConfirmation] = useState(false);
  const [vmData, setVmData] = useState();
  const { openDialog } = useConfirmDialog();  
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'warning', duration = 8000) => {   
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);    
  };

  const handleCloseSnapshotMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };


  useEffect(() => {
    //console.log('UseEffect...');
    loadVmSnapshots();    
  }, []);


  const loadVmSnapshots = async () => {
    //console.log("loadVmSnapshots: ",  node, vmid);
    const VMdata = await getSnapshots( node, vmid );
    //console.log('VMData: ', VMdata);
    setVmData(VMdata);
  };

  const getOpenDialog = async (header, message, _node, _vmid, _snapname) => {
    openDialog({
      title: 'Confirm Action For Rollback',
      messageHeader: header,
      message: message,
      onConfirm: async () => {        
        const result = await loadRollback(_node, _vmid, _snapname);
        const dataParts = result.data.split(':');
        const upidNode = `${dataParts[0]}:${dataParts[1]}`;  // "UPID:lab"
        const rollbackPart = dataParts[5];  // "qmrollback"
        const vmidFromData = dataParts[6];  // "122"
        // console.log("upidNode: ", upidNode, `UPID:${node}`);
        // console.log("vmidFromData: ", vmidFromData, vmid);
        if (upidNode.trim() === `UPID:${node.trim()}` && vmidFromData.trim() === vmid.toString().trim()) {
          showMessage(`VM-${vmid} Rollback is Success.`, "success");
        } else {
          showMessage(`VM-${vmid} Rollback is Failed.`, "error");
        }
      },
      onCancel: () => {
      //  console.log('Cancelled!');
      },
    });
  };
  
  const isLoadRollback = async (node, vmid, snapname) => {
    const header = "Rollback Definition Operation";
    const message = <>
    <p><strong>VM-{vmid}</strong> will rollback with <strong style={{ color: 'yellow', backgroundColor: 'lightcoral' }}>"{snapname}"</strong></p>
    <p>The current data of <strong>VM-{vmid}</strong> will be lost.</p>
    <p>Are you sure you want to do this?</p>
    </>;
    getOpenDialog(header, message, node, vmid, snapname);
  }; 

  const loadRollback = async (node, vmid, snapname) => {
    console.log("loadRollback: ",  node, vmid, snapname);
    const rollbackData = await snapshotRollback( node, vmid, snapname );
    console.log('loadRollback: ', rollbackData);
    return rollbackData;
    // const dataParts = rollbackData.data.split(':');
    // const upidNode = `${dataParts[0]}:${dataParts[1]}`;  // "UPID:lab"
    // const rollbackPart = dataParts[5];  // "qmrollback"
    // const vmidFromData = dataParts[6];  // "122"
    // console.log("upidNode: ", upidNode, `UPID:${node}`);
    // console.log("vmidFromData: ", vmidFromData, vmid);
    // if (upidNode.trim() === `UPID:${node.trim()}` && vmidFromData.trim() === vmid.toString().trim()) {
    //   //console.log("işlemi başarılı.");
    //   showMessage(`VM-${vmid} Rollback is Success.`, "success");
    // } else {
    //   //console.log(`VM-${vmid} Rollback is Failed.`, "error");
    //   showMessage(`VM-${vmid} Rollback is Failed.`, "error");
    // }
    //setVmData(result);
  };  

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpenSelectedTaskConfirmation(true);
    if (openSelectedTaskConfirmation) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose(false); // Parent bileşene kapatma bilgisini gönder
  };  

  const [isLoading, setIsLoading] = useState(false);

  // Helper to format the timestamp
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toISOString().replace("T", " ").split(".")[0];
  };

  // Dark theme setup
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1c1c1c', // Dark background color
        paper: '#2c2c2c',   // Darker background for the table
      },
      text: {
        primary: '#ffffff', // Lighter text for dark mode
      }
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
      body1: {
        color: '#ffffff',
      },
    },
  });
  
  return (
    <Grid sx={{ ml: 8 }}>
  <ThemeProvider theme={darkTheme}>
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Snapshot Details (VMID:{vmid})</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="snapshot table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderRight: '1px solid #444' }}>Name</TableCell>
                <TableCell sx={{ borderRight: '1px solid #444', alignItems: 'center' }}>RAM</TableCell>
                <TableCell sx={{ borderRight: '1px solid #444', width: '15%' }}>Date/Status</TableCell>
                <TableCell sx={{ borderRight: '1px solid #444', width: '40%' }}>Description</TableCell> {/* Genişliği artırdım */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("vmData: ", vmData)}
              {Array.isArray(vmData?.data) && vmData.data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ borderRight: '1px solid #444' }}>
                    {row.running === 1 && row.description === "You are here!" ? (
                      <>
                        <ComputerIcon sx={{ color: '#ffffff', alignItems: 'center' }} /> <Typography variant="body1">NOW</Typography>
                      </>
                    ) : row.parent ? (
                      <>
                        <UndoIcon sx={{ color: '#ffffff', alignItems: 'center' }} /> <Typography variant="body1">{row.name}</Typography>
                      </>
                    ) : (
                      <Typography variant="body1">{row.name}</Typography>
                    )}
                  </TableCell>
                  <TableCell sx={{ borderRight: '1px solid #444' }}>
                    {row.vmstate === 1 ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell sx={{ borderRight: '1px solid #444', width: '15'  }}>
                    {row.snaptime ? formatDate(row.snaptime) : 'N/A'}
                  </TableCell>
                  <TableCell sx={{ borderRight: '1px solid #444', width: '40%' }}>{row.description}</TableCell> {/* Genişliği artırdım */}
                  <TableCell>
                    {!(row.running === 1 && row.description === "You are here!") && (
                      <Button variant="contained" color="error" onClick={() => isLoadRollback(node, vmid, row.name)}>
                        Set Rollback
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40%' }}> {isLoading ? 'Loading...' : 'Create'} */}
        <Button onClick={handleClose} sx={{ mr: 5, alignItems: 'center', width: '7%', display: 'flex', justifyContent: 'space-between', fontSize: '20px', fontWeight: 'bold', }}>{t('Cancel')}</Button>
        {/* </Box> */}
        {/* <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{
            fontSize: isLoading ? '22' : '18',
            bgcolor: isLoading ? '#5141C3' : 'blue',
            color: 'white',
            maxWidth: '250px',
            ':hover': {
              bgcolor: isLoading ? '#5141C3' : '#5141C3',
              fontSize: isLoading ? '18' : '24'
            },
            ':disabled': {
              bgcolor: isLoading ? '#5141C3' : '',
              color: isLoading ? 'white' : '',
            }
          }}
          startIcon={isLoading ? <CircularProgress color="inherit" size={30} /> : null}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40%' }}> {isLoading ? 'Loading...' : 'Create'}
            <Box component="img" src='/assets/logo.svg' alt="Oak Academy logo" sx={{ marginLeft: 4, width: 50, height: 50, display: isLoading ? '/assets/logo.svg' : 'block' }} />
          </Box>
        </LoadingButton> */}
      </DialogActions>
    </Dialog>
  </ThemeProvider>
  <Snackbar 
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
    open={msgOpen}  
    autoHideDuration={5000} 
    onClose={handleCloseSnapshotMessage}
  >
    <Alert severity={severity} onClose={handleCloseSnapshotMessage} 
    sx={{ 
      width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
      fontStyle: 'normal',
      whiteSpace: 'pre-line' //  Burada \n yerine satir atlamak icin whiteSpace stilini uyguluyoruz.
    }} >
      <Typography style={{ whiteSpace: 'pre-line' }}>
        {alertMessage}
      </Typography>
    </Alert>
  </Snackbar>
</Grid>
  );
};