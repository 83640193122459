import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const MessageBox = ({ 
  msgOpen, 
  severity, 
  alertMessage, 
  handleCloseMessage, 
  autoHideDuration = 6000 
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={msgOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleCloseMessage}
    >
      <Alert
        severity={severity}
        onClose={handleCloseMessage}
        sx={{
          width: '100%',
          minWidth: 288,
          fontSize: 15,
          fontStyle: 'normal',
        }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default MessageBox;
