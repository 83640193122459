import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from  '../../sections/@dashboard/userprofile';
// mock
import POSTS from '../../_data/blog';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'mailbox', label: 'MailBox' },
  { value: 'interface', label: 'Web Interface' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function UserSetting() {
  return (
    <>
      <Helmet>
        <title> User Settings | OAK Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Settings
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Save
          </Button>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          {/* <BlogPostsSearch posts={POSTS} /> */}
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack>

        <Grid container spacing={3}>
          {POSTS.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid>
      </Container>
    </>
  );
}
