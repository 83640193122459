import React, { useState, useEffect, useRef } from 'react';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Typography, Card, CardContent, CardActionArea, 
Paper, Button, DialogActions, DialogTitle, DialogContent, Dialog, 
Snackbar, Checkbox, Popover, MenuItem, Tooltip, Stack, TableCell, TableRow, 
TableBody, TableContainer, Table, Alert, Container, Box, IconButton, 
Avatar, DynamicSelects, TablePagination, Divider, Breadcrumbs, Link } from '@mui/material';

import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';
import { StudentListHead, StudentListToolbar } from '../../sections/@dashboard/student';
import getInterviewTransaction from '../../_data/student/getInterviewTransaction';
import updateNewInterviewTask from '../../_data/student/updateNewInterviewTransaction';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar'

import config from '../../config';
import UpdateStudentDialog from '../../components/student/update-student-dialog';
import DeleteStudent from '../../_data/student/deleteStudent';
import ViewStudent from '../../_data/student/getViewStudent';
import getSession from '../../utils/getSession';
import CreateTransactionDialog from '../../components/student/create-transaction-dialog';
import AddTransactionDialog from '../../components/student/add-transaction-dialog';
import formatDateTime from '../../../src/utils/formatDateTime';
import StartIcon from '@mui/icons-material/Star';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import UndoIcon from '@mui/icons-material/Undo';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import { Bolt } from '@mui/icons-material';

const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;

const InterviewTransactionPage = () => {
  const { showLoading, hideLoading } = useLoading();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split('/').filter((x) => x && x !== 'dashboard'); // 'dashboard' segmentini filtrele

  // -------------------------COLUMN TITLES-------------------------------------------------- -----

  const TABLE_HEAD = [
    { id: 'contactdatetime', label: t('TransactionContactDate'), alignRight: false },
    { id: 'name_surname', label: t('TransactionBy'), alignRight: false },
    { id: 'contactchannel', label: t('Contact Device'), alignRight: false },
    { id: 'state', label: t('State'), alignRight: false },
    { id: 'status', label: t('Status'), alignRight: false },
    { id: 'howmanycall', label: t('How Many Call'), alignRight: false },
    { id: 'descriptionabout', label: t('Descriptions'), alignRight: false },
    { id: 'newaction', label: t('New Action'), alignRight: false },
    { id: 'nextcalldatetime', label: t('Next Call Date'), alignRight: false },
    //{ id: 'taskisdone', label: t('taskisdone'), alignRight: false },
    // { id: 'relatedtaskid', label: t('Related Task ID'), alignRight: false },
    { id: '' },
  ];
  // ---------------------------------------------------------------------------------------------------------
  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'AllExtract ', },
    { label4: 'Attendance', },
    { label5: 'Finance', },
  ];

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [transactionDatas, setTransactionDatas] = useState([]);
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedBatch, setSelectedBatch] = useState(""); //from
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");  
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedStdFullName, setSelectedStdFullName] = useState('');
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionDatas.length) : 0;
  const [filteredTaskId, setFilteredTaskId] = useState(null);
  const filteredStatuses = applySortFilter(transactionDatas, getComparator(order, orderBy), filterStatusId);
  const [filterRecords, setFilterRecords] = useState();
  const [stdNameUsername, setStdNameUsername] = useState();
  const [stdOccupation, setStdOccupation] = useState();
  const [stdLangName, setStdLangName] = useState();
  const [stdLangLevel, setStdLangLevel] = useState();
  const [stdAllReferences, setStdAllReferences] = useState();
  const [stdEmail, setStdEmail] = useState();
  const [stdContact, setStdContact] = useState();  
  //const [stdCountryData, setStdCountryData] = useState();  
  const [transactionById, setTransactionById] = useState();
  const [transactionByName, setTransactionByName] = useState();
  const [followerId, setFollowerId] = useState();
  const [followerNameSurname, setFollowerNameSurname] = useState();
  const interviewTransaction_stdid = location.state?.selectedId;
  const session = getSession();
  const [openCreateTransactionDialog, setOpenCreateTransactionDialog] = useState(false);
  const [openAddTransactionDialog, setOpenAddTransactionDialog] = useState(false);
  const [thisTaskIsDone, setThisTaskIsDone] = useState(false);
  const [assesmentLevel, setAssesmentLevel] = useState(false);
  // const filteredStudents = applySortFilter(transactionDatas, getComparator(order, orderBy), filterStudentId, filterStatusId);
  const filteredStudents = applySortFilter(transactionDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filteredTaskId);
  const isNotFound = !filteredStudents.length && (!!filterStudentId || !!filterStatusId);

  const colors = {
    default: '#E0E0E0', // Pastel gri
    relatedTask: '#FFCC80', // Pastel turuncu
    taskDone: '#80CBC4', // Pastel yeşil
    relatedTaskAndDone: '#FFD54F', // Pastel sarı
  };

  const getIconColor = (relatedtaskid, taskisdone) => {
    if (relatedtaskid > 0 && taskisdone) {
      return colors.taskDone;
    } else if (relatedtaskid > 0) {
      return colors.relatedTask;
    } else if (taskisdone) {
      return colors.taskDone;
    } else {
      return colors.default;
    }
  };


  const handleCreateTransactionDialogOpen = () => {
    setOpenCreateTransactionDialog(true);
  };
  
  const handleCreateTransactionDialogClose = () => {
    setOpenCreateTransactionDialog(false);
  };
  
  const handleAddTransactionDialogOpen = () => {
    setOpenAddTransactionDialog(true);
  };
  
  const handleAddTransactionDialogClose = () => {
    setOpenAddTransactionDialog(false);
  };


  useEffect(() => {   
     fetchTransactions();
  }, []);

  const fetchTransactions = async () => { 
    try {
      showLoading();
      const data = await getInterviewTransaction(interviewTransaction_stdid);
      setrecordsNumber(data.length);
      setTransactionDatas(data);
      const stddata = await ViewStudent(interviewTransaction_stdid); 
      setStdContact(stddata[0]?.contact);
      setStdNameUsername(`${stddata[0]?.name} ${stddata[0]?.surname}`);
      setTransactionById(session.user[0].iduser);
      setTransactionByName(session.user[0].name_surname);
      setFollowerId(stddata[0]?.follower); 
      setFollowerNameSurname(stddata[0]?.follower_namesurname);
      setStdOccupation(stddata[0]?.occupationname);
      setStdLangName(stddata[0]?.langname);
      setStdLangLevel(stddata[0]?.langlevelname);
      setStdEmail(stddata[0]?.email);
      setStdAllReferences(stddata[0]?.allreferences?.References);
    } catch (error) {
      console.error("Error for Fetch Data:", error);
    } finally {
      hideLoading();
  }
  };

  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
      if(selected.length > 0){
        switch(label){          
          case 'Edit':
            break;
          case 'Delete':
            handleConfirmDialogOpen(selected);
            break;
          // TODO: *****Burada dil tercigine gore gelen menu isimlerinin sorun olmamasi icin asagidaki gibi tum kodlari degistirmeliyiz*****
          case t('AddTransaction'):
            handleTransactionAddExtractDialogOpen(selected);          
            break;
          case 'Attendance':
            const mail = selectedUserName;
            handleStudentAttendancePageOpen(mail);
            break;
          case 'Finance':          
            break;
          case 'All Extract':
            setSeverity('warning');
            setAlertMessage('All connections are closed.');
            setMsgOpen(true);
            //handleConfirmExtractDialogOpen(selected);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredStudents.map((n) => ({
        id: n.idstudent_contacttransactions,
        mail: n.email,
        contact: n.contact,
        fullname: `${n.name} ${n.surname}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function applySortFilter(array, comparator, query, filterStatusId) {
    try {
      if (!Array.isArray(array)) {
        console.error('applySortFilter: Argument is not an array');
        return [];
      }
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]).filter((student) => {
        const lowerCaseQuery = String(query).toLowerCase(); 
        const matchQuery = query ?
          Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
          true;
          const matchTaskId = filteredTaskId ? student.idstudent_contacttransactions === filteredTaskId : true; // relatedtaskid'ye göre filtreleme
          return matchQuery && matchTaskId;
      });
    } catch (error) {
      console.log('error-applySortFilter: ', error);
    }
  };

  const handleConfirm = () => {    
    performAction(currentAction);
    handleConfirmDialogClose();
  };

  const handleConfirmAddNewTaskDialogClose = () => {
    setOpenConfirmAddNewTaskDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState();
  const handleConfirmDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenConfirmDialog(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  // const handleSelectionChange = (value) => {
  //   setSelectedBatch(value);
  //   setPage(0);
  //   setfilterStudentId(value);    
  // };

  const setNewTask = async() => {
    //setCurrentAction(select);
    handleAddTransactionDialogOpen();
    handleConfirmAddNewTaskDialogClose();
    // console.log("currentAction.id: ", currentAction[0].id);
    // const result = await updateNewInterviewTask(currentAction[0].id);
    // console.log("setNewTask-result: ", result);
  };

  const [openConfirmAddNewTaskDialog, setOpenConfirmAddNewTaskDialog] = useState(false);
  const handleConfirmAddNewTaskDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenConfirmAddNewTaskDialog(true);
  };

  const [openConfirmAddExtractDialog, setOpenConfirmAddExtractDialog] = useState(false);
  const handleTransactionAddExtractDialogOpen = (select) => {
    // console.log('selected: ', selected[0]);
   //  console.log('stdContact: ', stdContact.country);
    if(thisTaskIsDone || assesmentLevel === 7 ){
      // setSeverity('error');
      // setAlertMessage(t('NoAddTransactionMenu'));
      // setMsgOpen(true);
      handleConfirmAddNewTaskDialogOpen(select);
    }
    // else if(assesmentLevel === 7){
    //   setSeverity('error');
    //   setAlertMessage(t('NoAddStudentNewTransaction'));
    //   setMsgOpen(true);
    // }
    else{
      setCurrentAction(select);
      handleAddTransactionDialogOpen();
    }
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterStatusByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterStatusId('');
      return;
    }
    setPage(0);
    setfilterStatusId(newValue.id);
  };

  const handleStudentAttendancePageOpen = async (email) => {
  }

  const handleInterviewTransactionPageOpen = async (select) => {
    const interviewTransactions = await getInterviewTransaction(interviewTransaction_stdid);
  }

  const handleAllBatchAttendancePageOpen = async () => {
    const batchName = filterStudentId;
    if(filterRecords.length > 0){
      const getEmails = filterRecords.map((selected, index) => {
        return selected.contact.mail['mailaddress-1'];
      });
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleOpenMenu = (event, { idstudent_contacttransactions, email, name_surname, taskisdone, assesmentlevel, std_namesurname }) => {
    setOpen(event.currentTarget);
    setSelected([{ id: idstudent_contacttransactions, mail: email}]);
    setSelectedUserName(email);
    setSelectedFullName(`${name_surname}`);
    setSelectedStdFullName(`${std_namesurname}`);
    setThisTaskIsDone(taskisdone);
    setAssesmentLevel(assesmentlevel);
  };

  const updateDialogRef = useRef();
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const handleEditStudent = () => {
    try { 
      setSelectedStudentData(selected);
      if (updateDialogRef.current) {
        updateDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);;      
    }
  };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }  
    setSelected(newSelected);
  };

  const handleNewStudentAdded = async() => {
    try {
      fetchTransactions();
    } catch (error) {
      console.log("error: ", error);;      
    }
  };

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const isTodayOrFuture = (date) => {
    const now = new Date();
    const targetDate = new Date(date);
    return targetDate >= now.setHours(0, 0, 0, 0);
  };

  const isTomorrowOrPast = (date) => {
    const now = new Date();
    const targetDate = new Date(date);
    return targetDate < now.setHours(0, 0, 0, 0);
  };

  const performAction = async () => {
    try {
      const ids = currentAction.map((data, index) => {
        return data.id;      
      });
      const idsQueryString = ids.join(',');
      const response = await DeleteStudent(idsQueryString);
      if (response.message === 'Student deleted successfully.' && response.affectedRows > 0) {
        setSeverity('success');
        setAlertMessage('Student deleted successfully.');
        setMsgOpen(true);
        handleCloseMenu();
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const refreshStudentData = () => {
    getInterviewTransaction(interviewTransaction_stdid);
  };  

  const getTransactionDatas = async () => {
    try {
      const userId = session?.user[0]?.iduser;
      const data = await getInterviewTransaction(userId);
      setrecordsNumber(data.length);
    } catch (error) {
        console.error(error);
        getTransactionDatas([]);
    }
  };
  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // T ve Z karakterlerini kaldırıyoruz ve saniye ile salise kısımlarını kesiyoruz
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 16);
    return formattedDate;
  };
  // const replaceDateTime = (dateString) => {
  //   if (!dateString || typeof dateString !== 'string') {
  //     return '';
  //   };
  //   // T ve Z karakterlerini kaldırıyoruz
  //   const formattedDate = dateString.replace('T', ' ').replace('Z', '');
  //   return formattedDate;
  // };

  const Legend = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 2 }}>
    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
      <SubdirectoryArrowRightIcon sx={{ color: colors.relatedTask, mr: 0.5 }} /> Related Task Exists
    </Typography>
    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
      <SubdirectoryArrowRightIcon sx={{ color: colors.default, mr: 0.5 }} /> No Related Task
    </Typography>
    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
      <CheckIcon sx={{ color: colors.taskDone, mr: 0.5 }} /> Task Completed
    </Typography> 
    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
      <CheckIcon sx={{ color: colors.default, mr: 0.5 }} /> Task Not Completed
    </Typography>
    <Button variant="contained" color="secondary" onClick={() => setFilteredTaskId(null)}>
      Clear Filter
    </Button>
  </Box>
  );

  function TableFooter() {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Box sx={{ width: 20, height: 20, backgroundColor: 'lightblue', mr: 1 }} />
        <Typography variant="body2" sx={{ mr: 3 }}>Student[End Interaction]</Typography>
        <Box sx={{ width: 20, height: 20, backgroundColor: 'lightgreen', mr: 1 }} />
        <Typography variant="body2" sx={{ mr: 3 }}>Planned Call</Typography>
        <Box sx={{ width: 20, height: 20, backgroundColor: 'red', mr: 1 }} />
        <Typography variant="body2">Missed Call</Typography>
      </Box>
    );
  }
  const handleIconClick = (id) => {
    setFilteredTaskId(id);
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" onClick={() => navigate('/dashboard/student')}
        style={{ cursor: 'pointer' }}>
          Student Lists
        </Link>
        {pathnames.map((value, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography color="textPrimary" key={routeTo}>
              Interaction Details
            </Typography>
          ) : (
            <Link color="inherit" onClick={() => navigate(routeTo)} key={routeTo}>
              Interaction Details
            </Link>
          );
        })}
      </Breadcrumbs>
      {
        openAddTransactionDialog && (    
          <AddTransactionDialog 
            onTransactionAdded={handleNewStudentAdded} 
            follower={{ followerId: followerId, followerName: followerNameSurname }}
            studentId={interviewTransaction_stdid}
            transactionid= {selected[0].id}
            email={stdEmail}
            country={stdContact?.country}
            openDialog={openAddTransactionDialog}
            onClose={handleAddTransactionDialogClose}
          />
        )
      } 
      {/* <AppBar position="static" sx = {{backgroundColor: 'mycolor.teal'}}> */}
      <AppBar position="static" sx = {{backgroundColor: 'white'}}>
          <Toolbar>
              <Typography sx = {{color: 'black'}} variant="h6">
                Prospective Students Interview Transaction Details ( Record Number: {recordsNumber} )
               </Typography>
          </Toolbar>
      </AppBar>
      <Paper elevation={3} style={{margin: '20px', padding: '20px'}}>
        <div>          
          <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '16px',
                border: 1,
                borderColor: 'grey.300',
                p: 2,
                bgcolor: 'background.paper',
              }}
            >       
            <Card>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, pb:2, pt:1, ml:3, mr: 2 }}>
                  Name Surname:
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'normal', color: 'navy', fontSize: 12, mr:2 }}>
                  {stdNameUsername}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, mr: 2, ml:3, pb:1 }}>
                  Mail Address:
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'normal', color: 'navy', fontSize: 12, mr:2, pb:1, pr:2 }}>
                  {stdEmail}
                </Typography>
              </Box>
            </Card>
            <Card sx = {{ml:5}}>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, pb:2, pt:1, ml:3, mr: 2 }}>
                  Language Name/Level:
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'normal', color: 'navy', fontSize: 12, mr:2, pr:2 }}>
                  {`${stdLangName} / ${stdLangLevel}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, mr: 2, ml:3, pb:1 }}>
                  Occupation:
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'normal', color: 'mycolor.emeraldGreen', fontSize: 12, mr:2, pb:1, pr:2 }}>
                  {stdOccupation}
                </Typography>
              </Box>
            </Card>
            <Card sx = {{ml:5}}>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, pb:2, pt:1, ml:3, mr: 2 }}>
                  Country:
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'normal', color: 'navy', fontSize: 12, mr:2, pr:2 }}>
                  {/* {`${stdAllReferences}`}  */}
                  {`${stdContact?.country}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, mr: 2, ml:3, pb:1 }}>
                  Follower:
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'bold', color: 'navy', fontSize: 15, mr:2, pr:2 }}>
                  {`${followerNameSurname}`}
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ fontStyle: 'normal', color: 'mycolor.emeraldGreen', fontSize: 12, mr:2, pb:1, pr:2 }}>
                  {}
                </Typography>
              </Box>
            </Card>
              <CreateTransactionDialog 
                onTransactionAdded={handleNewStudentAdded} 
                follower={{ followerId: followerId, followerName: followerNameSurname }} 
                studentId={interviewTransaction_stdid} 
                email={stdEmail} 
                openDialog={true} 
                onClose={handleCreateTransactionDialogClose}
              />
          </Box>            
        </div>
      </Paper>
      {/* )} */}
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                {                  
                  (rowsPerPage === -1 ? filteredStudents : filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
                    .map((row, index) => {
                      const { idstudent_contacttransactions, std_namesurname, email, studentid, contactdatetime, transactionby, name_surname, 
                        contactchannel, contactenvironment, referencename, contactstate,  statename, newaction, nextcalldatetime, descriptionabout, 
                        assesmentlevel, levelname, howmanycall, relatedtaskid, taskisdone } = row;                      
                      const selectedStudent = selected.indexOf(idstudent_contacttransactions) !== -1;
                      const isItemSelected = selected.some(item => item.id === idstudent_contacttransactions);
                      const iconColor = getIconColor(relatedtaskid, taskisdone);                      
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}
                        sx={{
                         // backgroundColor: taskisdone === 1 ? 'white' :(levelname === "Student" ? 'lightblue' :( (isTomorrowOrPast(nextcalldatetime) ? 'red' :(isTodayOrFuture(nextcalldatetime) ? 'lightgreen' : 'inherit'))))
                         backgroundColor: taskisdone === 1 ? 'white' 
                          : (levelname === "Student" ? 'lightblue' 
                              : (nextcalldatetime == null || nextcalldatetime === '' ? 'inherit' 
                                  : (isTomorrowOrPast(nextcalldatetime) ? 'red' 
                                      : (isTodayOrFuture(nextcalldatetime) ? 'lightgreen' 
                                          : 'inherit'))))
                        }}
                      >
                        <TableCell padding="checkbox" sx={{ borderRight: 1, borderColor: 'divider' }}>
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, { id: idstudent_contacttransactions, mail: email, followerId: transactionby })}
                          />
                          {relatedtaskid > 0 ? ( //TODO: Bu kayit baska bir kayittan task olarak atanmistir
                             <SubdirectoryArrowRightIcon sx={{ color: colors.relatedTask, ml: 1, cursor: 'pointer', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.2)' } }} 
                             onClick={() => handleIconClick(relatedtaskid)} />
                          ) : (<SubdirectoryArrowRightIcon sx={{ color: colors.default, ml: 1 }} />)}
                          {taskisdone > 0 ? (
                            <CheckIcon sx={{ color: colors.taskDone, ml: 1 }} />
                          ) : (<CheckIcon sx={{ color: colors.default, ml: 1 }} />)}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" sx={{ borderRight: 1, borderColor: 'divider' }}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="London Zone Time" noWrap>      
                              {contactdatetime}                              
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ borderRight: 1, borderColor: 'divider'}}>{name_surname}</TableCell>
                        <TableCell align="left" sx={{ borderRight: 1, borderColor: 'divider' }}>{contactenvironment}</TableCell>                      
                        <TableCell align="left" sx={{ borderRight: 1, borderColor: 'divider' }}>
                          {statename}
                        </TableCell>
                        <TableCell align="left" sx={{ borderRight: 1, borderColor: 'divider', 
                          color: levelname === "Student" ? 'Red' : 'inherit', 
                          fontWeight: levelname === "Student" ? 'bold' : 'normal',
                          fontSize: levelname === "Student" ? '20px' : '14px' }}>{levelname}</TableCell> 
                        <TableCell align="left" sx = {{pl:2, borderRight: 1, borderColor: 'divider'}}>
                          {howmanycall === 0 ?  <strong>First Task</strong> : `${howmanycall}. Contact`}
                        </TableCell> 
                        <TableCell 
                          align="left" 
                          sx={{ 
                            borderRight: 1, 
                            borderColor: 'divider',
                            wordWrap: 'break-word', 
                            whiteSpace: 'normal', 
                            overflow: 'hidden',
                            maxWidth: '400px'
                          }}
                        >
                          {descriptionabout}
                        </TableCell>
                        <TableCell align="left" sx={{ borderRight: 1, borderColor: 'divider' }}>{newaction}</TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: 1,
                            borderColor: 'divider',
                          }}
                        >
                          {replaceDateTime(nextcalldatetime)}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton 
                            size="large" 
                            color="inherit" 
                            onClick={(event) => {
                              handleOpenMenu(event, {idstudent_contacttransactions, email, name_surname, taskisdone, assesmentlevel, std_namesurname})
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
      
        <Tooltip title = {t('InterviewTransactions')}>
          <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'success.dark', Width: 300}}>
            <Iconify icon={'eva:plus-fill'}  sx={{ mr: 2 }} />          
            {t('AddTransaction')}
          </MenuItem>
        </Tooltip>
       
        {/* <MenuItem key={MENU_OPTIONS.label1} onClick={handleEditStudent} sx={{ color: 'primary.main' , minWidth: 300}}>
          <Iconify icon={'eva:edit-2-fill'} sx={{ mr: 2 }} />
          {t('Edit')}
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleEditStudent} sx={{ color: 'text.primary' , minWidth: 300}}>
          <Iconify icon={'mdi:account-plus-outline'} sx={{ mr: 2, color: 'text.secondary' }} />
          {t('AssignFollower')}
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleEditStudent} sx={{ color: 'error.main' , minWidth: 300}}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('Delete')}
        </MenuItem> */}
      </Popover>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 550, minWidth: 300, height: 90, fontSize: 13, fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The registration of the student(s) whose above information is visible will be deleted.
            <br/>
            Are you sure you want to do this?
            
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            OK
          </Button>
        </DialogActions>        
      </Dialog>

      <Dialog
        open={openConfirmAddNewTaskDialog}
        onClose={handleConfirmAddNewTaskDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirmation!"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedStdFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            This record has been marked as "Student" or non-follower!
            <br/>
            Do you approve adding a new task to the student's registration calendar?
          </p>
              <br/>
              Are you sure you want to do this?
        </DialogContent>
        <DialogActions>
          <Button sx = {{ px: 6, py: 2 }} onClick={handleConfirmAddNewTaskDialogClose} color="primary">
            Cancel
          </Button>
          <Button 
             sx={{
              px: 6, // Sağ-sol padding
              py: 2, // Yukarı-aşağı padding
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', // Yatay ve dikey ortalamayı sağlar
            }}
            onClick={setNewTask} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            OK
          </Button>
        </DialogActions>        
      </Dialog>

      <Legend />
      <TableFooter />
    </div>
  );
};

export default InterviewTransactionPage;