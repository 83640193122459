import React, { useState, useEffect } from 'react';
import { Typography, Box, Stack, Collapse, TableRow, TableCell, 
  Divider, Grid, TableHead, TableBody, IconButton, Dialog, DialogTitle, DialogContent,
  TextField, Select, MenuItem, InputLabel, FormControl, Button, 
  Tooltip} from '@mui/material';
import getStudentFinancialDatas from '../../_data/finance/getStudentFinancialDatas';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { useTranslation } from 'react-i18next'; //Multi Language
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalance from '@mui/icons-material/AccountBalance';





const FinancialPaymentDetails = ({ isRowOpen, name, surname,  isFinanceRole, idstudent }) => {
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [studentFinancialData, setStudentFinancialData] = useState([]);

  useEffect(() => {    
    getFinancialDatas(idstudent);
  }, [idstudent]);

  const getFinancialDatas = async (idstudent) => {
    try {
      showLoading();
      if (isFinanceRole) {
        const data = await getStudentFinancialDatas(idstudent);
        setStudentFinancialData(data);
      } else {
        // TODO: You do not have permission!
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 10);
    return formattedDate;
  };

  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [paidCharge, setPaidCharge] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [currency, setCurrency] = useState('€');
  const [transactionId, setTransactionId] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [providerEmail, setProviderEmail] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  // Gizli alanlar
  const [idPaymentDetails] = useState('12345'); // Gizli alan
  const [idStudentPayments] = useState('54321'); // Gizli alan

  const handleSubmit = () => {
    // Form işlemleri burada yapılabilir
    console.log({
      paidCharge,
      paymentMethod,
      currency,
      transactionId,
      accountNumber,
      providerEmail,
      additionalInfo,
      idPaymentDetails,
      idStudentPayments,
    });
    handleClose(); // Formu kapat
  };

  return (
    <>
    
      <TableHead>      
        <TableRow>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Payment Type</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Payment Frequency</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Amount</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Duedate</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Payment Date</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Paid Charge</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Status</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {studentFinancialData?.length > 0 ? (          
          studentFinancialData?.map((item, index) => (
            <TableRow 
              key={index}
              style={{
                // backgroundColor: item.status === 'PAID' ? '#fff9c4' : 'inherit',
                backgroundColor: item.status === 'PAID' ? '#e0f7e9' : 'inherit',
              }}
            >
              <TableCell sx={{width: '12%', textAlign: 'center', verticalAlign: 'middle', color: item.paymenttypename === "Registiration Fee" ? 'green' : 'black'
                ,fontWeight: item.paymenttypename === "Registiration Fee" ? 'bold' : 'normal', borderRight: '1px solid #ccc'
              }}> {item.paymenttypename}</TableCell>
              <TableCell sx={{width: '9%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{item.paymentfrequency}</TableCell>
              <TableCell sx={{width: '8%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{item.currency} {item.amount}</TableCell>
              <TableCell sx={{width: '8%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{replaceDateTime(item.duedate)}</TableCell>
              <TableCell sx={{width: '8%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{replaceDateTime(item.paymentdate)}</TableCell>
              <TableCell sx={{width: '10%', color: item.paid_charge > 0 ? 'blue' : 'black'
                ,fontWeight: item.paid_charge > 0 ? 'bold' : 'normal', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'
              }}>{item.currency}{item.paid_charge} 
                <Tooltip title="When you receive a payment, you can use this button to complete the necessary financial records!">
                  <IconButton 
                    onClick={handleClickOpen} 
                    aria-label="paid-charge"
                    disabled={item.paid_charge > 0}
                  >
                    {/* <AttachMoneyIcon /> */}
                    <AccountBalance />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell sx={{width: '8%', color: item.status === "PAID" ? 'blue' : 'red'
                ,fontWeight: item.status === "PAID" ? 'bold' : 'normal', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'
              }}>{item.status}</TableCell>
              <TableCell sx={{width: '35%', textAlign: 'left', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{item.additionalInfo}</TableCell>
            </TableRow>
          ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                No financial data available
              </TableCell>
            </TableRow>
          )}
          { studentFinancialData?.length > 0 ? (
            <TableRow sx={{backgroundColor: '#f5f5f5'}}>
              <TableCell sx={{ color: '#ff5722', fontWeight: 'bold'}} colSpan={8}>
              <Typography variant="body1">
                <strong>NOTE:</strong> 
                <span style={{ color: 'black', fontWeight: 'bold'  }}> {studentFinancialData[0]?.description}</span>          
              </Typography>
              </TableCell>
            </TableRow>
            ): null
          }
      </TableBody>

      {/* <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Paid Charge Details</DialogTitle>
        <DialogContent>
          <p>Odeme Methodu: methodname, (secim yapilacak drop-down combobox bir eleman)</p>
          <p>PK(idpaymentdetails, idstudent_payments) (gizli elemanlar useState icine alinabilir.)  </p>
          <p>currency: € OR £ OR $ paid_charge: 0.00 (currency alani para birimi olarak 3 secim yapilacak sekide olabilir)</p>
          <p>transactionId: Bir text alan el ile islem numaramizi girebiliriz.</p>
          <p>accountNumber: Bir text alan el ile account number girilebilir.</p>
          <p>providerEmail: Odeme methodu Wie Payoneer gibi platformlardan ise islem yapan mail adresi girilebilir.</p>
          <p>additionalInfo: Yapilan islem ile ilgili detay bilgi sonradan kendimize hatirlatma bilgileri yazilabilir.</p>
        </DialogContent>
      </Dialog>   */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Paid Charge Details</DialogTitle>
        <DialogContent>
          {/* Odeme Methodu (Drop-down combobox) */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="payment-method-label">Payment Metodu</InputLabel>
            <Select
              labelId="payment-method-label"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <MenuItem value="Credit Card">Credit Card</MenuItem>
              <MenuItem value="Payoneer">Payoneer</MenuItem>
              <MenuItem value="Wise">Wise</MenuItem>
            </Select>
          </FormControl>

          {/* Currency */}
          <FormControl fullWidth margin="normal">
            <Select
              labelId="currency-label"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <MenuItem value="€">€ Euro</MenuItem>
              <MenuItem value="£">£ Pound</MenuItem>
              <MenuItem value="$">$ USD</MenuItem>
            </Select>
          </FormControl>

          {/* Paid Charge */}
          <TextField
            label="Paid Charge"
            fullWidth
            margin="normal"
            value={paidCharge}
            onChange={(e) => {
              // Yalnızca sayısal ve ondalık girişleri kabul et
              const value = e.target.value;
              const regex = /^\d*\.?\d{0,2}$/; // Sadece 0 veya 2 ondalık basamak kabul edilir
              if (regex.test(value)) {
                setPaidCharge(value); // Geçerli bir değerse state'i güncelle
              }
            }}
            onBlur={() => {
              // Eğer kullanıcı ondalık girmemişse, .00 ekle
              if (!paidCharge.includes('.')) {
                setPaidCharge((prevValue) => `${prevValue}.00`);
              } else if (paidCharge.split('.')[1].length === 1) {
                // Eğer sadece bir ondalık basamak varsa, bir sıfır ekle
                setPaidCharge((prevValue) => `${prevValue}0`);
              }
            }}
            inputProps={{
              inputMode: 'decimal', // Mobil cihazlarda daha iyi kullanıcı deneyimi sağlar
              pattern: '[0-9]*.[0-9]{0,2}', // 2 ondalık basamak sınırlaması
            }}
          />
          {/* <TextField
            label="Paid Charge"
            fullWidth
            margin="normal"
            value={paidCharge}
            onChange={(e) => {
              // Yalnızca sayısal ve ondalık girişleri kabul et
              const value = e.target.value;
              const regex = /^\d*\.?\d{0,2}$/; // Sadece 0 veya 2 ondalık basamak kabul edilir
              if (regex.test(value)) {
                setPaidCharge(value); // Geçerli bir değerse state'i güncelle
              }
            }}
            inputProps={{
              inputMode: 'decimal', // Mobil cihazlarda daha iyi kullanıcı deneyimi sağlar
              pattern: '[0-9]*.[0-9]{0,2}', // 2 ondalık basamak sınırlaması
            }}
          /> */}
          {/* <TextField
            label="Paid Charge"
            fullWidth
            margin="normal"
            value={paidCharge}
            onChange={(e) => setPaidCharge(e.target.value)}
          /> */}

          {/* Transaction ID */}
          <TextField
            label="Transaction Number"
            fullWidth
            margin="normal"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
          />

          {/* Account Number */}
          <TextField
            label="Account Number"
            fullWidth
            margin="normal"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />

          {/* Provider Email */}
          <TextField
            label="Provider E-Mail"
            fullWidth
            margin="normal"
            value={providerEmail}
            onChange={(e) => setProviderEmail(e.target.value)}
          />

          {/* Additional Info */}
          <TextField
            label="Transaction Description"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />

          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 2 }}>
            Save
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FinancialPaymentDetails;