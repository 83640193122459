import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getIpAddressInfo = async (IpAddress) => {
  try {    
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/mailchimp/getcountryfromip`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: { IpAddress: IpAddress}
      });
      const info = response.data || [];
      return info;
  } catch (error) {
    console.log("getIpAddressInfo-Error: ", error);
    return [];
  }
};


export { getIpAddressInfo};